<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.flex-container{
  width: 100%;
  overflow: hidden;
}

p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.intro-grid-container{
    display: grid;
    grid-template-rows: 30px 200px 60px 417px 10px;
    grid-template-columns: 3% 1fr 3%;
    padding-bottom: 0px;
    padding-top: 50px;
}

.intro-item1 {
    font-size: 42px;
    text-shadow: 1px 1px #000000;
    visibility: visible;
    grid-column: 1 / 3;
    grid-row: 2 / span 1;
    color: #EAB749;
    margin-left: 7%;
    z-index: 4;
    animation-delay: 0.3s;
    font-family: 'Playfair Display';
  }

.intro-text {
    grid-column: 2 / 3;
    grid-row: 3/ 4;
    background-color: #383838;
    z-index: 2;
    height: 495px;
    box-shadow: 1px 1px 1px #000000;
    animation-delay: 1.4s;
}

.intro-text p{
  padding: 5%;
    line-height: 1.4em;
    margin-top: 50px;
    font-size: 18px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    color: #ffffff;
    font-weight:100;
    z-index:7;
    animation-delay: 1.4s;
}

.intro-bar {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  background-color: #EAB749;
  width: 40px;
  height: 5px;
  justify-self: end;
  margin-top: -70px;
  animation-delay: 0.3s;
}

.intro-bar2 {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  background-color: #EAB749;
  width: 50px;
  height: 5px;
  z-index: 1;
  justify-self: end;
  align-self: center;
  margin-top: -15px;
  margin-right: -30px;
      animation-delay: 0.3s;
}

.intro-bernard {
    grid-column: 2 / 5;
    justify-self: end;
    grid-row: 2 / 5;
    z-index: 1;
    background-size: 200px 200px;
    width: 200px;
    height: auto;
    margin-top: 50px;
    margin-right: 20px;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F53103289.96406264-P2_carpio.png?alt=media&token=89d71924-8739-413e-9a2b-2f050914603a);
    background-repeat: no-repeat;
    animation-delay: 1.4s;
}

.flex-container-center {
  background-color: #000000;
  justify-content: center;
  display:flex;
}

@media (min-width: 767px) {
  .intro-grid-container {
    grid-template-rows: 200px 96px 48px 417px 130px;
    grid-template-columns: 220px 550px 35px;
    // padding-bottom: 500px;
  }

  .intro-item1 {
    grid-column: 1 / 3;
    grid-row: 2 / span 3;
    color: #EAB749;
    z-index: 4;
    font-size: 2.9rem;
    text-shadow: 1px 1px #000000;
    visibility: visible;
  }

 .intro-text {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    background-color: #383838;
    z-index: 2;
    height: 400px;
    box-shadow: 1px 1px 1px #000000;
    animation-delay: 1.4s;
  }

  .intro-bernard {
    grid-column: 2 / 4;
    justify-self: end;
    grid-row: 2 / 5;
    z-index: 1;
    width: 250px;
    height: auto;
    margin-top: -25px;
    background-repeat: no-repeat;
    animation-delay: 1.4s;
  }

  .intro-bar {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
    background-color: #EAB749;
    width: 40px;
    height: 5px;
    justify-self: end;
    margin-top: -70px;
  }

  .intro-bar2 {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    background-color: #EAB749;
    width: 50px;
    height: 5px;
    z-index: 1;
    justify-self: end;
    align-self: center;
    margin-top: -15px;
    margin-right: -30px;
  }
}

.texture-bg {
	background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F7682869.17677965-texture.png?alt=media&token=96233608-6f62-4446-b777-c06356d7ddf8");
}

/* Fade in Text Keyframe Animation CSS */


@keyframes fadeInLeft {
  0% {
      opacity: 0;
      transform: translateX(-20px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-fill-mode: both;
}
.animated {
  animation-duration: 2s;
}
.hide{
  opacity: 0;
}
.taller{
  min-height: 100vh;
}
</style>

<template>
  <div class="flex-container">
     <div class="texture-bg flex-container-center">
          <div class="taller">
              <div class="intro-grid-container" v-if="word('text')">
                    <h1 class="intro-item1 animated" :class="{ 'fadeInLeft': fadeIn, 'hide': !fadeIn }"  v-html="word('title')"></h1>
                    <div class="intro-text animated" :class="{ 'fadeInLeft': fadeIn, 'hide': !fadeIn }">
                      <p class="animated" :class="{ 'fadeInLeft': fadeIn, 'hide': !fadeIn }" v-html="word('text')"></p>
                    </div>
                    <div class="intro-bernard animated" :class="{ 'fadeInLeft': fadeIn, 'hide': !fadeIn }"></div>
                    <div class="intro-bar animated" :class="{ 'fadeInLeft': fadeIn, 'hide': !fadeIn }"></div>
                    <div class="intro-bar2 animated" :class="{ 'fadeInLeft': fadeIn, 'hide': !fadeIn }"></div>
              </div>
            </div>
      </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
  name: 'P2',
  computed: {
    ...mapGetters({
      'findWord': 'words/findWord'
    }),
    word () {
      return (id) => {
        return this.findWord('home-intro-grid', id)
      }
    }
  },
  props: {
    fadeIn: {
      type: Boolean,
      default: false
    }
  }
}
</script>
