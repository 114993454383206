<style lang="scss" scoped>
  .events{
    width: 100%;
  }
  .events-header{
    width: 100%;
    height: 41.17vw;
    // height: 157px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F36783521.28442433-event-iphone.png?alt=media&token=13184f95-10ca-43d2-a10a-dbaaff367b19");
    background-size: contain;
    // background-size: 415px 157px;
    background-color: #0e0d0b;
    background-position: center top;
    background-repeat: no-repeat no-repeat;
  }
  .block{
    width: 100%;
    height: 103px;
    background-color: #F6CD45;
  }

  @media (min-width: 767px) {
    .events-header{
      background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F91416967.47970787-events-ipad.png?alt=media&token=a400870a-de14-420e-8560-5a2bcae6e94d");
      // height: 310px;
      height: 41.17vw;
      background-size: contain;
      // background-size: 769px 310px;
    }
    .block{
      height: 122px;
    }
  }

  @media (min-width: 1365px) {
    .events-header{
      background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F6549117.502357049-events-ipad-pro.png?alt=media&token=6471c47e-9a17-46ff-8590-b7b989606d90");
      // height: 562px;
      height: 41.17vw;
      background-size: contain;
      // background-size: 1374px 562px;
    }
    .block{
      height: 131px;
    }
  }

</style>

<template>
  <div class="events">

    <MenuComp :tiny="true" />
    <div class="events-header"></div>
    <div class="block"></div>
    <EventsComp :events="events" />

    <P9 />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import P9 from '@/components/Home/P9'
import MenuComp from '@/components/Home/Menu'
import EventsComp from '@/components/Home/Events'
export default {
  name: 'EventsPage',
  components: {
    EventsComp, P9, MenuComp
  },
  computed: {
    ...mapGetters({
      'events': 'events/reverseEvents',
      'canLoadOlder': 'events/canLoadOlder',
      'canLoadNewer': 'events/canLoadNewer'
    })
  },
  methods: {
    loadOlder () {
      this.$store.dispatch('events/loadEvents', { mode: 'older', num: 25 })
    },
    loadNewer () {
      this.$store.dispatch('events/loadEvents', { mode: 'newer', num: 25 })
    }
  },
  mounted () {
    this.$store.dispatch('events/loadEvents', { mode: 'init', num: 25 })
  },
  data () {
    return {
    //   events: [
    //     {
    //       date: 'Jun 14, 2017',
    //       time: '9pm - 12am',
    //       title: 'The Notes of Carpio Part III',
    //       venue: 'Grand Hyatt Podium',
    //       location: 'Wan Chai Hong Kong'
    //     },
    //     {
    //       date: 'Jun 14, 2017',
    //       time: '9pm - 12am',
    //       title: 'The Notes of Carpio Part III',
    //       venue: 'Grand Hyatt Podium',
    //       location: 'Wan Chai Hong Kong'
    //     },
    //     {
    //       date: 'Jun 14, 2017',
    //       time: '9pm - 12am',
    //       title: 'The Notes of Carpio Part III',
    //       venue: 'Grand Hyatt Podium',
    //       location: 'Wan Chai Hong Kong'
    //     },
    //     {
    //       date: 'Jun 14, 2017',
    //       time: '9pm - 12am',
    //       title: 'The Notes of Carpio Part III',
    //       venue: 'Grand Hyatt Podium',
    //       location: 'Wan Chai Hong Kong'
    //     }
    //   ]
    }
  }
}
</script>

