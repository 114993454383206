<style lang="scss" scoped>
@import "../../layout.scss";

.fade {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

.fade:hover {
  opacity: 0.5;
}

.footer {
  width: 100vw;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;

  // background-color: rgba(244,205,75, 1.0);
  background-color: #000000;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
}

a {
  color: #ffffff;
  text-decoration: none;
}

@media (orientation: landscape) and (max-height: 500px) {
  .footer {
    min-height: 100vmax;
  }
}

.pre-padding {
  height: 50px;
}

.extra-padding {
  height: 50px;
}

.footer-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.site-map {
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  color: #ffffff;
  line-height: 31px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  padding-bottom: 20px;
}
.fade {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.fade:hover {
  opacity: 0.5;
}

.sitemap-item {
  cursor: pointer !important;
  z-index: 50;
}

.sitemap-item:hover {
  opacity: 0.5;
}
.social-icon {
  max-width: 40px;
  max-height: 40px;
  margin: 7.5px;
}

.brand-img {
  max-width: 139.33px;
}
.logo-box {
  margin: 20px;
}

.ig {
  margin-right: 14px;
}

.copyright {
  /* © Copyright Carpio M: */
  font-family: AvenirNext-Regular;
  font-size: 12px;
  color: #ffffff;

  width: 100%;
  text-align: center;
}

@media (min-width: 767px) {
  .footer-flex {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 57px;
  }
  .site-map {
    order: 2;
    width: 285px;
    flex-basis: 50%;
    text-align: left;
    padding-bottom: 20px;
    // width: 454px;
  }
  .social-media {
    order: 3;
  }
  .brand-logo {
    order: 1;
  }
  .footer {
    height: 512px;
  }
  .copyright {
    text-align: right;
    width: calc(100% - 4%);
  }
}
@media (min-width: 1365px) {
  .site-map {
    width: 454px;
  }
  .copyright {
    width: calc(100% - 6%);
  }
}
</style>

<template>
  <div class="footer" id="footer">
    <div class="pre-padding"></div>

    <div class="footer-con">
      <div class="layout-rp footer-flex">
        <div class="site-map">
          <router-link tag="a" to="/about" class="sitemap-item fade"
            >About</router-link
          ><br />
          <router-link
            tag="a"
            to="/live-entertainment"
            class="sitemap-item fade"
            >Live Entertainment</router-link
          ><br />
          <router-link tag="a" to="/artists" class="sitemap-item fade"
            >Artists</router-link
          ><br />
          <router-link tag="a" to="/bands" class="sitemap-item fade"
            >Bands</router-link
          ><br />
          <router-link tag="a" to="/education" class="sitemap-item fade"
            >Education</router-link
          ><br />
          <router-link tag="a" to="/clients" class="sitemap-item fade"
            >Clients</router-link
          ><br />
          <router-link tag="a" to="/contact-us" class="sitemap-item fade"
            >Contact us</router-link
          >
        </div>
        <div class="social-media">
          <div class="social-media-box">
            <a href="https://www.facebook.com/carpiomusic/"
              ><img
                class="social-icon fb fade"
                src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F79804694.85633077-facebook_white.png?alt=media&token=2c6f9b90-ebea-4d4f-a7d8-eed01f80f8e2"
            /></a>
            <a href="https://www.instagram.com/carpiomusichk/"
              ><img
                class="social-icon ig fade"
                src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F30961067.34356856-instagram_white.png?alt=media&token=15679bb6-8b6c-41ae-b3da-7a02cb35745f"
            /></a>
            <a href="https://www.youtube.com/channel/UCYHpIEmC8YQHm87sQ6pofDQ"
              ><img
                class="social-icon yt fade"
                src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F25941082.814143356-youtube_white.png?alt=media&token=f024c0d1-d684-48c0-b919-a14c9af55f58"
            /></a>
          </div>
        </div>
        <div class="brand-logo">
          <div class="logo-box">
            <img
              class="brand-img"
              src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/cms%2Fimages%2Fhome%2Fcarpiomusic-logo.png?alt=media&token=b8a248e2-66c2-48b8-b327-079b5817e8f3"
            />
          </div>
        </div>
      </div>
      <div class="copyright">
        © Copyright Carpio Music 2022 <span> | </span> Web design by
        <a class="86deck-link fade" href="http://www.86deck.com">86 Deck</a>
      </div>
    </div>

    <div class="extra-padding"></div>
  </div>
</template>

<script>
export default {};
</script>
