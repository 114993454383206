<style lang="scss" scoped>

.artists {
  background-color: #101010;
}
h1 {
  font-weight: 100;
}
.header{
  position: relative;
  width: 100%;
  height: 41.06vw;
  // max-height: 50vh;
  background-size: contain;
  // height: 155px;
  // background-size: 415px 155px;
  background-position: top center;
  background-color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F26577330.232839346-artist_heading_BW.jpg?alt=media&token=355d20c1-ba02-454a-b7ab-b41362864d14");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
}

.desc{
  width: 100%;
  height: 161px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
}
.desc-txt{
  width: 258.5px;
}

.pics{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.pic{
  max-width: 50vw;
  flex-basis: 50vw;
  height: 50vw;
  position: relative;
  cursor: pointer;
}
.pic-img{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.pic-desc{
  opacity: 0;
  transition: opacity 1s ease;
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50vw;
  height: 50vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pic:hover,
.pic:active {
  .pic-desc{
    opacity: 1;
  }
}

.pic-desc-name{
  /* Tony Carpio Director: */
  font-family: PlayfairDisplay-Regular;
  font-size: 25px;
  line-height: 33px;
  color: #F6CD45;
}
.pic-desc-title{
  font-family: AvenirNext-Regular;
  font-size: 12px;
  line-height: 33px;
  color: white;
}
.header-title-bg{
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.4);
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-title{
   font-family: 'Playfair Display', serif;
   color: #EAB749;
   font-size: 34px;
   z-index: 10;
}

.back-btn-wrap{
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}


.back-btn-con{
  min-height: 5vh;
  width: 300px;
}

.back-btn{
  color: white;
}

@media (min-width: 767px) {

  .back-btn-con{
    width: 600px;
  }

  .header{

    height: 28.27vw;
    background-size: contain;

    // height: 293px;
    // background-size: 768px 293px;
    background-position: top center;
    background-color: black;
  }
  .header-title {
      font-size: 42px;
    }
  .desc{
    height: 122.38px;
  }
  .desc-txt{
    width: 760.44px;
  }
  .pic{
    max-width: 25vw;
    flex-basis: 25vw;
    height: 25vw;
  }
  .pic-desc{
    width: 25vw;
    height: 25vw;
  }
}

@media (min-width: 1365px) {
  .back-btn-con{
    width: 907px;
  }

  .header{

    height: 19.27vw;
    background-size: contain;

    // height: 536px;
    // background-size: 1444px 536px;
  }
  .header-title {
      font-size: 42px;
    }
  .desc-txt {
    width: 866px;
  }
}

@media (min-width: 1439px) {
  .header{

    height: 19.27vw;
    background-size: contain;

    // height: 536px;
    // background-size: 1444px 536px;
  }
  .desc-txt {
    width: 866px;
  }
  .pics{
    margin-left: calc((100vw - 907px) / 2);
    width: 907px;
  }
  .pic{
    max-width: calc((907px) / 4);
    flex-basis: calc((907px) / 4);
    height: calc((907px) / 4);
  }
  .pic-desc{
    width: calc((907px) / 4);
    height: calc((907px) / 4);
  }
}
</style>

<template>
  <div class="artists">
    <div class="header">
      <div class="header-title-bg">
        <h1 class="header-title">Artists</h1>
      </div>
      <div class="back-btn-wrap">
        <div class="back-btn-con"><router-link to="/" class="back-btn" tag="div">← Back</router-link></div>
      </div>

    </div>
    <div class="desc">
      <div class="desc-txt">{{ word('intro-1') }}</div>
    </div>

    <MenuCompo :tiny="true" />

     <div class="pics">
      <!-- <transition-group name="list" tag="div" class="pics"> -->
        <div class="pic" :key="ppl" v-for="ppl in artists" @touchstart="" @click="goDetail(ppl)">
          <img class="pic-img" :src="ppl.imgSrc" />
          <div class="pic-desc">
            <div class="pic-desc-name">{{ ppl.name }}</div>
            <div class="pic-desc-title">{{ ppl.title }}</div>
          </div>
        </div>
      <!-- </transition-group> -->
     </div>

    <P9 />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'

export default {
  components: {
    P9, MenuCompo
  },
  data () {
    return {
      // artists: [
      //   {
      //     name: 'Tony Carpio',
      //     title: 'Director',
      //     imgSrc: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F7152848.571862536-Tony_Carpio.png?alt=media&token=9cd788e7-13b9-4876-a06d-9e8161f07753',
      //     paragraphs: [
      //       {
      //         text: ''
      //       }
      //     ]
      //   },
      //   {
      //     name: 'Tony Carpio',
      //     title: 'Director',
      //     imgSrc: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F7152848.571862536-Tony_Carpio.png?alt=media&token=9cd788e7-13b9-4876-a06d-9e8161f07753',
      //     paragraphs: [
      //       {
      //         text: ''
      //       }
      //     ]
      //   }
      // ]
    }
  },
  computed: {
    artists () {
      return this.artistsSource.sort((a, b) => {
        return parseFloat(a) > parseFloat(b)
      })
    },
    word () {
      return (id) => {
        return this.findWord('artist-list-page', id)
      }
    },
    ...mapGetters({
      'findWord': 'words/findWord',
      'artistsSource': 'artists/artists'
    })
  },
  mounted () {
    this.$store.dispatch('words/loadWords', { useRealtime: !!this.$store.state.user })
    this.$store.dispatch('artists/loadArtists', { useRealTime: false, mode: 'init' })
  },
  methods: {
    goDetail (artist) {
      this.$router.push({
        path: `/artists/${artist.name.toLowerCase().split(' ').join('-')}`
      })
    }
  }
}
</script>
