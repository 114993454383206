const namespace = 'artists'
export const ns = namespace
export const pfx = ns + '/'
import { artists } from '@/firebase'
import { firebaseAction } from 'vuexfire'
// const codebase = db.ref('codebase')

const state = {
  realtimeArtists: [],
  staticArtists: [],
  artistMode: 'staticArtists',
  currentArtistIndex: 0,
  loadCursor: 0
}

const getters = {
  canLoadNewer (state) {
    return state.loadCursor > 0
  },
  canLoadOlder (state, getters) {
    return getters.artists.length > 1
  },
  reverseArtists (state, getters) {
    return getters.artists.slice().reverse() || []
  },
  artists (state) {
    return state[state.artistMode] || []
  },
  currentArtist (state, getters) {
    var artists = getters.artists
    if (typeof artists[0] !== 'undefined') {
      return artists[state.currentArtistIndex]
    } else {
      return false
    }
  }
}

const mutations = {
  setCurrentArtistIndex (state, index) {
    state.currentArtistIndex = index
  },
  clearRealtimeArtists (state) {
    state.realtimeArtists = []
  },
  useRealtimeArtists (state) {
    state.artistMode = 'realtimeArtists'
  },
  saveStaticArtists (state, staticArtists) {
    if (staticArtists) {
      state.staticArtists = Object.keys(staticArtists).map((key, index) => {
        return {
          '.key': key,
          ...staticArtists[key]
        }
      })
    } else {
      state.staticArtists = []
    }
  }
}

function getQuery ({ query, arr, state, mode, num }) {
  // var query = artists
  if (mode === 'init') {
    state.loadCursor = 0
    if (num) {
      query = query.orderByKey().limitToLast(num)
    }
  } else if (mode === 'older') {
    let target = arr[arr.length - 1]
    if (!target) {
      throw new Error('needs target')
    }
    let cursor = target['.key']
    if (!cursor) {
      throw new Error('needs key')
    }
    query = query.orderByKey().endAt(cursor).limitToLast(num)
    state.loadCursor++
  } else if (mode === 'newer') {
    let target = arr[0]
    if (!target) {
      throw new Error('needs target')
    }
    let cursor = target['.key']
    if (!cursor) {
      throw new Error('needs key')
    }
    query = query.orderByKey().startAt(cursor).limitToFirst(num)
    state.loadCursor--
  }
  return query
}

const actions = {
  loadArtists ({ dispatch }, { useRealtime, mode, num }) {
    if (useRealtime) {
      dispatch('loadRealtimeArtists', { mode, num })
    } else {
      dispatch('loadStaticArtists', { mode, num })
    }
  },
  loadStaticArtists ({ state, commit, getters }, { mode, num }) {
    var query = getQuery({ query: artists, arr: getters.reverseArtists, state, mode, num })
    query.once('value').then((snapshot) => {
      commit('saveStaticArtists', snapshot.val())
    })
  },
  loadRealtimeArtists: firebaseAction(function ({ state, commit, unbindFirebaseRef, bindFirebaseRef, getters }, { mode, num }) {
    var query = getQuery({ query: artists, arr: getters.reverseArtists, state, mode, num })
    commit('useRealtimeArtists')
    if (typeof state.realtimeArtists[0] !== 'undefined') {
      commit('clearRealtimeArtists')
      unbindFirebaseRef('realtimeArtists')
    }
    bindFirebaseRef('realtimeArtists', query)
  }),
  addArtist ({ state }, data) {
    return artists.push({
      name: 'My New Artist',
      ...data
    })
  },
  removeArtist ({ state, dispatch }, artist) {
    if (window.confirm('Delete?')) {
      artists.child(artist['.key']).remove()
    }
  },
  saveArtist ({ state }, artist) {
    let newObj = { ...artist }
    delete newObj['.key']
    artists.child(artist['.key']).set(newObj)
  }
}

const moduleObj = {
  mutations,
  ns,
  pfx,
  namespaced: true,
  state,
  actions,
  getters,
  use,
  clean
}

function use (store) {
  store.registerModule(ns, moduleObj)
  // @___@'v
  //

  // store.subscribe((mutation, state) => {
  //   // called after every mutation.
  //   // The mutation comes in the format of `{ type, payload }`.
  // })
}

function clean (store) {
  store.unregisterModule(ns)
}

export default moduleObj
