import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import About from '@/components/About'

import Artists from '@/components/Artists'
import ArtistDetail from '@/components/Artists/detail'

import Bands from '@/components/Bands'
import BandDetail from '@/components/Bands/detail'

import ClientDetail from '@/components/Clients/detail'

import ClientsPage from '@/components/Clients'

import EducationPage from '@/components/Education'
import LiveEntertainmentPage from '@/components/Live-entertainment'
import EventsPage from '@/components/Events'
import ContactPage from '@/components/Contact-us'

// import CMS from '@/components/CMS'
// import SEO from '@/components/CMS/SEO'
// import Photos from '@/components/CMS/Photos'
// import Gallery from '@/components/CMS/Gallery'
// import Youtubes from '@/components/CMS/Youtubes'

const CMS = resolve => require.ensure([], () => { resolve(require('@/components/CMS')) }, 'cms')
const SEO = resolve => require.ensure([], () => { resolve(require('@/components/CMS/SEO')) }, 'cms')
const Photos = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Photos')) }, 'cms')
const Gallery = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Gallery')) }, 'cms')
const Youtubes = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Youtubes')) }, 'cms')
const Events = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Events')) }, 'cms')
const Words = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Words')) }, 'cms')
const Contacts = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Contacts')) }, 'cms')
const ArtistsCMS = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Artists')) }, 'cms')
const BandsCMS = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Bands')) }, 'cms')
const ClientsCMS = resolve => require.ensure([], () => { resolve(require('@/components/CMS/Clients')) }, 'cms')

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/artists',
      name: 'artists',
      component: Artists
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/live-entertainment',
      name: 'Live Entertainment',
      component: LiveEntertainmentPage
    },
    {
      path: '/education',
      name: 'Education',
      component: EducationPage
    },

    {
      path: '/bands',
      name: 'bands',
      component: Bands
    },
    {
      path: '/clients',
      name: 'clients',
      component: ClientsPage
    },
    {
      path: '/Contact-us',
      name: 'Contact-us',
      component: ContactPage
    },
    {
      path: '/artists/:aid',
      name: 'artists-detail',
      component: ArtistDetail
    },
    {
      path: '/bands/:bid',
      name: 'band-detail',
      component: BandDetail
    },
    {
      path: '/clients/:bid',
      name: 'client-detail',
      component: ClientDetail
    },
    {
      path: '/events',
      component: EventsPage
    },
    {
      path: '/cms',
      component: CMS,
      children: [
        {
          path: '',
          component: SEO
        },
        {
          path: 'photos',
          component: Photos
        },
        {
          path: 'gallery',
          component: Gallery
        },
        {
          path: 'youtube',
          component: Youtubes
        },
        {
          path: 'events',
          component: Events
        },
        {
          path: 'words',
          component: Words
        },
        {
          path: 'contacts',
          component: Contacts
        },
        {
          path: 'artists',
          component: ArtistsCMS
        },
        {
          path: 'bands',
          component: BandsCMS
        },
        {
          path: 'clients',
          component: ClientsCMS
        }
      ]
    }
  ]
})
