<style lang="scss" scoped>
// html, body, #heroimage{
//   width:100vw;
//   height:100vh;
// }

// #heroimage{
//   background:url('http://placehold.it/1920x1080&text=Hero+Image') center center;
//   background-size:cover;
// }

// #content{
//   padding:20px;
// }

.header{
  position:relative;
  overflow:hidden;
  // display:flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: flex-start;
  // align-content: flex-start;
  height:100vh;
  // min-height:400px;
/*   max-height:550px; */
  // min-width:300px;
  width: 100vw;
  // color:#000;
}
// .header:after{
//   content:"";
// //   width:100%;
// //   height:40%;
// //   position:absolute;
// //   bottom:0;
// //   left:0;
// //   z-index:-1;
// //  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(27,32,48,1) 100%);
// }
.header:before{
  content:"";
  width:100vw;
  height:100vh;
  position: absolute;
  top:0;
  left:0;
    // -webkit-backface-visibility: hidden;
    // -webkit-transform: translateZ(0) scale(1.0, 1.0);
    // transform: translateZ(0);

  // background:#000  top center no-repeat;
  background-color: #000;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F13398180.58929092-18121042_1249586768487448_2712921532293427601_o-3.jpg?alt=media&token=35142f09-c27e-4494-ab86-58c0e617a142);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  animation: grow 60s linear 10ms infinite both;
  transition: all 0.2s ease-in-out;
  // z-index:-2
}

body.not-ready .header:before{
  background-attachment: scroll;
}
body.is-touched-once .header:before{
  background-attachment: scroll;
}

@keyframes grow{
  0% { transform:scale(1)}
  50% { transform:scale(1.2)}
  100% { transform: scale(1.0) }
}


</style>

<template>
<div class="header">

</div>

</template>

<script>
export default {

}
</script>
