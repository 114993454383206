<style lang="scss" scoped>
.back-btn{
  color: white;
}
</style>

<template>
  <ItemDetail :info="currentArtist">
    <MenuCompo :tiny="true" slot="nav" />
    <P9 slot="footer" />
    <router-link tag="span" to="/artists" slot="back-btn" class="back-btn">← Back</router-link>
  </ItemDetail>
</template>

<script>
import { mapGetters } from 'vuex'

import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'
import ItemDetail from '@/components/Reusable/ItemDetail'

export default {
  components: {
    P9, MenuCompo, ItemDetail
  },
  name: 'artist-detail',
  data () {
    return {
    }
  },
  computed: {
    headerStyle () {
      return {
        backgroundImage: `url(${this.heroImg})`
      }
    },
    heroImg () {
      return this.currentArtist.heroImgSrc || ''
    },
    currentArtist () {
      return this.artists.filter((artist) => {
        return artist.name.toUpperCase().split(' ').join('-').trim() === this.$route.params.aid.toUpperCase().trim()
      })[0] || {}
    },
    word () {
      return (id) => {
        return this.findWord('artist-list-page', id)
      }
    },
    ...mapGetters({
      'findWord': 'words/findWord',
      'artists': 'artists/artists'
    })
  },
  mounted () {
    // this.$store.dispatch('words/loadWords', { useRealtime: !!this.$store.state.user })
    this.$store.dispatch('artists/loadArtists', { useRealTime: false, mode: 'init' })
  }
}
</script>
