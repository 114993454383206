

<style lang="scss" scoped>

.events-con{
  margin-top: 15px;
  width: 100%;
  margin-bottom: 30px;
}

.events-list{
}

.events-item{
  margin-top: 15px;
  width: 100%;
}

.events-title{
  height: 67px;
  background-color: #000000;
  color: #F6CD45;
}

.events-desc{
  height: 67px;
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, #e6e6e6 50%, #000000 50%);
  color: black;
  transition: background-position 0.25s;
}

.events-item:hover .events-desc{
          background-position: 0 100%;
          color: #e6e6e6;
    }
.events-item:hover .events-show{
          color: #e6e6e6;
    }

.events-date{
  height: 20px;
  padding-bottom: 10px;
}
.events-bar{
  width: 2px;
  font-size: 20px;
  line-height: 20px;
  padding: 0px 10px;
  color: #D7a53A;
}
.events-time{
  font-family: AvenirNext-Regular;
  font-size: 12px;
}

.events-show,
.events-venue{
  font-family: AvenirNext-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
}

.events-location{
  font-family: PlayfairDisplay-Regular, 'Playfair Display', serif;
  font-style: italic;
  font-size: 10px;
}

.events-title,
.events-desc{
  display: flex;
  justify-content: center;
}

.event-title-sub{
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: PlayfairDisplay-Regular, 'Playfair Display', serif;
  font-size: 20px;
  color: #F6CD45;
  letter-spacing: 0;
  width: 200px;
}
.event-desc-sub{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 200px;
}

@media (min-width: 767px) {

  .title-bar{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F26468468.524395682-cover.png?alt=media&token=4ff6184d-1d0f-4c0e-918f-352a03685a1f");
    height: 277px;
    background-size: contain;
  }
  .title-box{
    margin-top: 114px;
  }
  .events-con{
    display: flex;
    justify-content: center;
  }
  .events-item{
    width: 600px
  }
  .resp-con{
    width: 480px;
  }
  .events-list{
    width: 600px;
  }
}

@media (min-width: 1365px){
  .resp-con{
    width: 907px;
  }
  .title-bar{
    // background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F26468468.524395682-cover.png?alt=media&token=4ff6184d-1d0f-4c0e-918f-352a03685a1f");
    height: 277px;
  }
  .events-list{
    width: 907px;
  }
  .events-item{
    width: 907px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .events-title{
    width: 369px;
    height: 110px;
    flex-basis: 369px;
    font-size: 1.4rem;
  }
  .events-desc{
    width: 539px;
    flex-basis: 539px;
    height: 110px;
  }
  .event-title-sub{
    padding-left: 35px;
  }
  .event-desc-sub{
    padding-left: 35px;
  }
  .events-venue{
    display: none;
  }
  .events-date{
    font-size: 1.1rem;
  }
  .events-location{
    font-size: 0.8rem;
  }

  .events-show,
  .events-venue {
    font-family: AvenirNext-Regular;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0;
  }


}
</style>

<template>
  <!--<div>-->
    <div class="events-con">
      <div class="events-list">
        <router-link :key="eventIndex" tag="div" to="/events" class="events-item" v-for="(event, eventIndex) in events">
          <div class="events-title">
            <div class="resp-con event-title-sub">
              <div class="events-date">{{ convDate(event.date) }}</div>
              <div class="events-bar">|</div>
              <div class="events-time">{{ event.time }}</div>
            </div>
          </div>
          <div class="events-desc">
            <div class="resp-con event-desc-sub">
              <div class="events-show">{{ event.title }}</div>
              <div class="events-venue">{{ event.venue }}</div>
              <div class="events-location">{{ event.location }}</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  <!--</div>-->
</template>

<script>
import moment from 'moment'

export default {
  name: 'EventsComp',
  props: ['events'],
  methods: {
    convDate (event) {
      return moment(event.date).format('LL')
    }
  }
}
</script>
