<template>
<div class="vid-grid-section">

  <div class="flex-container">
    <div class="center-wrapper">
      <div class="center-column">
        <h1 class="title">
          Videos
        </h1>

        <carousel paginationActiveColor="#F6CD45" paginationColor="#bababa" :perPage="1">
          <slide v-for="yt in homeVideos.videos" class="slide">
            <div class="slide-content">
              <img class="thumb" v-if="!yt.active" :src="getThumbnail(yt.url)" @click="activateItem(yt)" />
              <span v-if="!yt.active">
                <br />
                {{ yt.desc }}
              </span>
              <iframe class="youtube" v-if="yt.active === true && yt.type === 'youtube'" width="280" height="157.5" :src="getEmbedLink(yt.url, true)" frameborder="0" allowfullscreen></iframe>
            </div>
          </slide>
        </carousel>

      </div>
    </div>
  </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'

export default {
  data () {
    return {
      yt: false,
      overlayOpen: false
    }
  },
  components: {
    Carousel,
    Slide
  },
  computed: {
    ...mapGetters({
      'youtubesSource': 'youtubes/youtubes'
    }),
    homeVideos () {
      return (this.youtubesSource || []).filter((videos) => {
        return videos.title === 'Home Videos'
      })[0] || {}
    }
  },
  mounted () {
    this.$store.dispatch('youtubes/loadYoutubes', { useRealTime: false, mode: 'init' })
  },
  methods: {
    activateItem (item) {
      item.active = true
      this.$forceUpdate()
    },
    getEmbedLink (url, autoplay) {
      let vid = ''

      if (url.indexOf('https://youtu.be/') !== -1) {
        url = url.replace('https://youtu.be/', '')
        if (url.indexOf('?t=') !== -1) {
          let arr = url.split('?t=')
          vid = arr[0]
        } else {
          vid = url
        }
      } else if (url.indexOf('https://www.youtube.com/watch?v=') !== -1) {
        vid = url.replace('https://www.youtube.com/watch?v=', '')
      }
      if (!vid) {
        return false
      } else {
        return `https://www.youtube.com/embed/${vid}?rel=0${autoplay ? '&autoplay=1' : ''}`
      }
    },
    getThumbnail (url) {
      let vid = ''

      if (url.indexOf('https://youtu.be/') !== -1) {
        url = url.replace('https://youtu.be/', '')
        if (url.indexOf('?t=') !== -1) {
          let arr = url.split('?t=')
          vid = arr[0]
        } else {
          vid = url
        }
      } else if (url.indexOf('https://www.youtube.com/watch?v=') !== -1) {
        vid = url.replace('https://www.youtube.com/watch?v=', '')
      }
      if (!vid) {
        return false
      } else {
        return `http://img.youtube.com/vi/${vid}/hqdefault.jpg`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../layout.scss";

@import "../../layout.scss";

.vid-grid-section{
  background-color: black;
  padding-top: 50px;
}

.vid-grid{
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.vid-item{
  cursor:pointer;
  width: 37%;
  padding: 4% 0%;
}

@media (max-width: 500px) {
  .vid-item{
    width: 96%;
    padding: 4% 0%;
  }
}

.thumb {
  cursor: pointer;
  width: 100%;
}

.flex-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 70px;
}

.center-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
}

.center-column{
  width: 100%;
  max-width: 1200px;
}

.title{
  text-align: left;
  // font-size: 36px;
  color: white;
  line-height: 44px;
  padding-bottom: 30px;

  font-family: 'Playfair Display', serif;
  font-size: 34px;
  // color: #000;
  // margin-top: 10vh;
  font-weight: 100;
  margin-top: 31px;
}


.slide{
  // width: 100%;
  height: calc(70vmin);
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.slide-content{
  width: 100%;
}

.slide img {
  // width: 100%;
  height: 100%;
}

.youtube{
  width: calc(80vmin);
  height: calc(60vmin);
  max-width: 100%;
}

</style>
