<style lang="scss" scoped>
@import "../../layout.scss";

.events{
  width: 100vw;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;

  background-color: white;
  background-repeat: repeat repeat;
  background-position: center center;
}

@media (orientation: landscape) and (max-height: 500px) {
  .events{
    min-height: 100vmax;
  }
}

.resp-con{
  width: 288px;
}

.title {
  /* Artists: */
  font-family: PlayfairDisplay-Regular, 'Playfair Display', serif;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 44px;
}

.title-bar{
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F75643176.58881196-cover.png?alt=media&token=a27ac5b3-926c-4e02-af1c-f23c3a4b1b16");
  width: 100%;
  display: flex;
  // justify-content: center;

  // // background-size: cover;
  // background-repeat: no-repeat no-repeat;
  // background-position: center center;

  justify-content: center;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: contain;
  height: 38vw;
  flex-direction: column;
  align-items: center;
}

.title-box{
  display: flex;
  justify-content: center;
  flex-direction: column;
  // margin-top: 58px;
  // height: 100px;
}

// .yellow-line{
//   width: 107px;
//   height: 3px;
//   background-color: #F6CD45;
//   margin-top: 9px;
//   margin-left: -2px;
// }


.learn-more{
  cursor: pointer;
  width: 173px;
  height: 52px;
  /* Rectangle 4: */
  background: #000000;
  font-family: Raleway-Bold, 'Raleway', sans-serif;
  font-size: 11px;
  color: #FFFFFF;
  letter-spacing: 1.56px;
  // line-height: 21px;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  text-align: center;
  line-height: 52px;
  transition-property: background-color;
}

.learn-more:hover {
  background-color: #D7a53A;
}


.learn-more-box{
  width: 100%;
  height: 110px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.pre-padding{
  height: 100px;
}
.post-padding{
  height: 100px;
}


.events-con{
  margin-top: 15px;
  width: 100%;
}

@media (min-width: 767px) {
  .title-bar{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F26468468.524395682-cover.png?alt=media&token=4ff6184d-1d0f-4c0e-918f-352a03685a1f");
    // height: 277px;
    height: 20.5vw;
    background-size: contain;
  }
  .title-box{
    height: 20.5vw;
    margin-top: 0px;
    // margin-top: 114px;
  }
  .resp-con{
    width: 480px;
  }
}

@media (min-width: 1365px){
  .resp-con{
    width: 907px;
  }
  .title-bar{
    // background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F26468468.524395682-cover.png?alt=media&token=4ff6184d-1d0f-4c0e-918f-352a03685a1f");
    // height: 277px;
  }

}

</style>


<template>
  <div class="events layout-rp" :style="`background-image: url(${word('golden-texture')});`">
 

    <div class="title-bar">
      <div class="resp-con">
        <div class="title-box">
          <div class="title">Events</div>
          <!--<div class="yellow-line"></div>-->
        </div>
      </div>
    </div>

    <EventsComp :events="events" />

    <div class="learn-more-box">
      <router-link tag="div" to="/events" class="learn-more">SEE ALL EVENTS</router-link>
    </div>

    <div class="post-padding"></div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventsComp from './Events'
export default {
  components: {
    EventsComp
  },
  name: 'P5',
  data () {
    return {
      // events: [
      //   {
      //     date: 'Jun 14, 2017',
      //     time: '9pm - 12am',
      //     title: 'The Notes of Carpio Part III',
      //     venue: 'Grand Hyatt Podium',
      //     location: 'Wan Chai Hong Kong'
      //   },
      //   {
      //     date: 'Jun 14, 2017',
      //     time: '9pm - 12am',
      //     title: 'The Notes of Carpio Part III',
      //     venue: 'Grand Hyatt Podium',
      //     location: 'Wan Chai Hong Kong'
      //   },
      //   {
      //     date: 'Jun 14, 2017',
      //     time: '9pm - 12am',
      //     title: 'The Notes of Carpio Part III',
      //     venue: 'Grand Hyatt Podium',
      //     location: 'Wan Chai Hong Kong'
      //   },
      //   {
      //     date: 'Jun 14, 2017',
      //     time: '9pm - 12am',
      //     title: 'The Notes of Carpio Part III',
      //     venue: 'Grand Hyatt Podium',
      //     location: 'Wan Chai Hong Kong'
      //   }
      // ]
    }
  },
  computed: {
    ...mapGetters({
      'findWord': 'words/findWord',
      'events': 'events/reverseEvents'
    }),
    word () {
      return (id) => {
        return this.findWord('home-events', id)
      }
    }
  },
  mounted () {
    this.$store.dispatch('events/loadEvents', { mode: 'init', num: 4 })
  }
}
</script>
