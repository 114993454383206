<style lang="scss" scoped>


p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;
}

h1 {
    font-family: 'Playfair Display', serif;
    font-size: 34px;
    color: #D7a53A;
    margin-top: 10vh;
    font-weight: 100;
}

.flex-container {
    position: relative;
display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

@media (max-width: 767px) {
    .flex-container{
        margin-left:10vw;
        margin-right:10vw;
    }
}

.container1 {
    z-index: 3;
}
.container2 {
    z-index: 3;
}
.container3 {
    display:none;
    align-self: flex-end;
    z-index: 0;
    // margin-top: -377px;
    // margin-right: -18%;
    // width: 80vh;

    width: 80%;
    position: absolute;
    bottom: 130px;
    right: -59px;

}
.container3 img{
    width:100%;
    }
.clients-holder {
    width:80vw;
    margin-left:10vw;
    margin-right:10vw;
    padding-bottom: 170px;
}
.clients-holder img{
    width:100%;
}
.clients-holder-lg{
    display: none;
}


.wrapper{
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;

    // margin-left:10vw;
    // margin-right:10vw;
}

.wrapper-center{
}

@media (min-width: 767px) {
    .wrapper{
        // margin: none;
    }
     .flex-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 130px;
        min-width: 767px;
    }
    .container1 {
        display: flex;
        flex:1;
        align-items: center;
        max-width: 400px;
    }
    .container2 {
        flex:1;
        max-width: 380px;
    }
    .container3 {
        display: block;
        z-index: 0;
        margin-top: -377px;
        // margin-right: -28%;
        width: 645px;

        bottom: 40px;
        right: -90px;
    }
    h1 {
        font-size: 40px;
    }
    p {
       font-size: 16px;
    }
    .clients-holder {
    width:640px;
    margin:0 auto;
    }
    .clients-holder img{
        width:100%;
    }
}

@media (min-width: 1365px) {
         .flex-container {
        display: flex;
        flex-direction: column;
        // margin-left:0vw;
        // margin-right:0vw;
    }
    .container1 {
        display: flex;
        flex:1;
        align-items: center;
        max-width: 400px;
    }
    .container2 {
        flex:1;
        max-width: 600px;

    }
    .container3 {
    z-index: 0;
    margin-top: -377px;
    // margin-right: -10%;
    bottom: 20px;
    right: 0px;
    }
    h1 {
        font-size: 40px;
    }
    p {
       font-size: 16px;
    }
    .clients-holder {
    display:none
    }
    .clients-holder img{
        width:100%;
    }
    .clients-holder-lg{
    width: 1200px;
    display: block;
    margin: 0 auto;
    padding-bottom: 170px;
    }
    .clients-holder-lg img{
    width: 100%;

    }
}

@media (min-width: 1439px) {
         .flex-container {
        display: flex;
        flex-direction: column;
        // margin-left:10vw;
        // margin-right:10vw;
    }
    .container1 {
        display: flex;
        flex:1;
        align-items: center;
        max-width: 400px;
    }
    .container2 {
        flex:1;
        max-width: 600px;

    }
    .container3 {
    z-index: 0;
    margin-top: -377px;
    // margin-right: 0%;
    }
    h1 {
        font-size: 40px;
    }
    p {
       font-size: 16px;
    }
}


</style>
<template>
<div class="wrapper">

    <div class="wrapper-center">

        <div class="flex-container">
            <div class="container1">
                <h1>Hong Kong’s Premier Live Entertainment group</h1>
            </div>
            <div class="container2">
                <p>Carpio Music represents the full musical experience in Education, Performance, Production and Recording. In our hearts, music is an art form that needs to be nurtured and educated on a continuous basis, no matter of age or experience.
                </p>
                <p>Carpio Music's rich history spans over 40 years, establishing itself as a leader in the Asian music industry, providing entertainment to some of the most exclusive clients including The King of Malaysia and The Hong Kong Handover Ceremony
                </p>
                </div>
                <div class="container3">
                    <img
                    src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F7122633.345811646-chris-carpio%401x.jpg?alt=media&token=1cc3d724-9144-4d61-b592-1da416910a68"
                    sizes="(max-width: 500px) 100vw, 100vw"
                    srcset="
                        https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F7122633.345811646-chris-carpio%401x.jpg?alt=media&token=1cc3d724-9144-4d61-b592-1da416910a68 1x,
                        https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F84262482.37246639-chris-carpio%402x.jpg?alt=media&token=ad824692-a7c5-4585-930e-cc67d9464bb9 2x
                    ">
                    <!-- <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F42832024.18987127-Chris_CarpioMusic_edited1.jpg?alt=media&token=f49d3d54-4390-41ec-a515-2feee712d7af"> -->
                </div>
                </div>

                <div class="clients-holder">
                    <router-link to="/clients">
                        <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F13261188.61268586-carpiomusicclients-02.png?alt=media&token=05e82685-b647-451f-ba86-df7b1a10e80c">
                    </router-link>
                </div>
                <div class="clients-holder-lg">
                    <router-link to="/clients">
                        <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F27719636.819829408-carpiomusic-clients01.png?alt=media&token=b19d0cdc-e5f7-4911-a1ad-441e3c5d17f3">
                    </router-link>
                </div>

        </di>

    </div>

</div>
</template>

<script>
export default {

}
</script>
