<template>
  <div id="app">
    <!-- <transition name="fadeapp"> -->
    <router-view></router-view>
    <!-- </transition> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "app",
  computed: {
    ...mapState({
      user: "user",
      pages: (state) => state.pages[state.pages.pageMode],
    }),
    path() {
      return this.$route.path;
    },
    pagesLength() {
      return (this.pages || []).length;
    },
  },
  methods: {
    ...mapActions({
      loadPages: "pages/loadPages",
    }),
    updateTitle() {
      let page =
        this.pages.filter((page) => {
          return page.path === this.path;
        })[0] || false;

      if (page) {
        window.document.title = page.title;
      }
    },
  },
  mounted() {
    window.addEventListener(
      "touchstart",
      function onFirstTouch() {
        document.body.classList.add("is-touched-once");
        document.body.classList.remove("not-ready");
        window.removeEventListener("touchstart", onFirstTouch);
      },
      { passive: true }
    );

    if ("ontouchstart" in document.documentElement) {
      document.body.classList.add("is-touched-once");
      document.body.classList.remove("not-ready");
    } else {
      document.body.classList.remove("not-ready");
    }

    this.loadPages({ useRealtime: !!this.user });
    // this.$router.beforeEach(function (to, from, next) {
    //   window.scrollTo(0, 0)
    //   next()
    // })
  },
  watch: {
    user() {
      this.loadPages({ useRealtime: !!this.user });
    },
    path() {
      this.updateTitle();
    },
    pagesLength() {
      this.updateTitle();
    },
  },
};
</script>

<style lang="scss">
@import "./normalise.scss";

.fadeapp-enter-active,
.fadeapp-leave-active {
  position: absolute;
  top: 0px;
  left: 0px;
  transition: opacity 1.35s;
  /*min-width: 100%;*/
  /*height: 100vh;*/
}
.fadeapp-enter,
.fadeapp-leave-to {
  opacity: 0;
  /*min-width: 100%;
  height: 100vh;*/
}

.fadeapp-enter .footer,
.fadeapp-leave-to .footer {
  display: none;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  width: 100%;
  min-height: 100%;

  font-family: "Avenir", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
}
</style>
