<style lang="scss" scoped>
h2{
  font-family: 'Playfair Display',serif;
  font-size:30px;
  font-weight:100;
}

ul {
  columns: 1;
}
ul li{
  line-height: 40px;
}

.header{
  width: 100%;
  height: 41.06vw;
  // max-height: 50vh;
  background-size: cover;
  // height: 155px;
  // background-size: 415px 155px;
  background-position: top center;
  background-color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F38719098.064676814-header_education.png?alt=media&token=e7ea347d-f3f4-4fee-afa8-96382adf0109");
  // background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title-bg{
   width: 40%;
   height: 40%;
   background: rgba(0, 0, 0, 0.4);
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-title{
   font-family: 'Playfair Display', serif;
   color: #EAB749;
   font-size: 28px;
   z-index: 10;
}

// .desc{
//   width: 100%;
//   height: 161px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #F6CD45;
// }
// .desc-txt{
//   width: 258.5px;
// }


// .picture-container{
//     display:flex;
//     align-items: center;
//     flex-direction: column;
//   }
// .picture-container img {
//     width: 330px;
//     // height: 100%;
//     padding-top:10px;
//   }
// .education-content{
//     padding: 8%;
//   }

// .list-container {

// }
.container{
    max-width: 960px;
  }

@media (min-width: 767px) {
  .header{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F38719098.064676814-header_education.png?alt=media&token=e7ea347d-f3f4-4fee-afa8-96382adf0109");

    height: 41.27vw;
    background-size: cover;

    // height: 293px;
    // background-size: 768px 293px;
    background-position: top center;
    background-color: black;
  }
  .header-title-bg{
   width: 40%;
   height: 40%;
  }
  .header-title {
    font-size: 42px;
  }
  // .desc{
  //   height: 122.38px;
  // }
  // .desc-txt{
  //   width: 760.44px;
  // }

}

@media (min-width: 1365px) {
  .header{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F38719098.064676814-header_education.png?alt=media&token=e7ea347d-f3f4-4fee-afa8-96382adf0109");

    height: 41.27vw;
    background-size: cover;

    // height: 536px;
    // background-size: 1444px 536px;
  }
  .header-title-bg {
     width: 40%;
   height: 40%;
  }
   .header-title {
    font-size: 42px;
  }
  // .desc-txt {
  //   width: 866px;
  // }
  // .picture-container{
  //   display:flex;
  //   justify-content: space-between;
  //   flex-direction: row;
  // }
  // .picture-container img {
  //   width: 310px;
  //   // height: 100%;
  // }
  // ul {
  //   columns: 2;
  // }
}

@media (min-width: 1439px) {
  .header{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F38719098.064676814-header_education.png?alt=media&token=e7ea347d-f3f4-4fee-afa8-96382adf0109");

    height: 41.27vw;
    background-size: cover;

    // height: 536px;
    // background-size: 1444px 536px;
  }
  .header-title-bg{
     width: 40%;
   height: 40%;
  }
  .header-title {
    font-size: 42px;
  }
  // .desc-txt {
  //   width: 866px;
  // }
  // .pics{
  //   margin-left: calc((100vw - 907px) / 2);
  //   width: 907px;
  // }
  // .pic{
  //   max-width: calc((907px) / 4);
  //   flex-basis: calc((907px) / 4);
  //   height: calc((907px) / 4);
  // }
  // .pic-desc{
  //   width: calc((907px) / 4);
  //   height: calc((907px) / 4);
  // }
  // .education-content{
  //   padding:0%;
  // }
  // .container{
  //   max-width: 960px;
  // }
  // .education-content .container p{
  //   line-height:24px;
  // }
  // .picture-container{
  //   display:flex;
  //   justify-content: space-between;
  //   flex-direction: row;
  // }
  // .picture-container img {
  //   width: 310px;
  //   // height: 100%;
  // }
  // ul {
  //   columns: 2;
  // }
}

// Responsive
@mixin responsive() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

$content-font: Avernir, Cardo;
$heading-font: 'Playfair Display',
sans-serif;
$timeline-width:700px;
$timeline-container-width:100%;
.timeline {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: $timeline-width;
  position: relative;
  &__content {
    &-title {
      font-weight: normal;
      font-size: 66px;
      margin: -10px 0 0 0;
      transition: .4s;
      padding: 0 10px;
      box-sizing: border-box;
      font-family: $heading-font;
      color: #fff;
    }
    &-desc {
      margin: 0;
      font-size: 15px;
      box-sizing: border-box;
      color: rgba(#fff, .9);
      font-family: $content-font;
      font-weight: normal;
      line-height: 25px;
    }
  }
  &:before {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    content: "";
    background: rgba(255, 255, 255, .07);
    @include responsive() {
      left: 40px;
    }
  }
  &-item {
    padding: 40px 0;
    opacity: .3;
    // filter: blur(2px);
    transition: .5s;
    box-sizing: border-box;
    width: calc(50% - 40px);
    display: flex;
    position: relative;
    // transform: translateY(-80px);
    &:before {
      content: attr(data-text);
      letter-spacing: 3px;
      width: 100%;
      position: absolute;
      color: rgba(#fff, .5);
      font-size: 13px;
      font-family: $heading-font;
      border-left: 2px solid rgba(#fff, .5);
      top: 70%;
      margin-top: -5px;
      padding-left: 15px;
      opacity: 0;
      right: calc(-100% - 56px);
    }
    &:nth-child(even) {
      align-self: flex-end;
      &:before {
        right: auto;
        text-align: right;
        left: calc(-100% - 56px);
        padding-left: 0;
        border-left: none;
        border-right: 2px solid rgba(#fff, .5);
        padding-right: 15px;
      }
    }
    &--active {
      opacity: 1;
      // transform: translateY(0);
      // filter: blur(0px);
      &:before {
        top: 50%;
        transition: .3s all .2s;
        opacity: 1;
      }
      .timeline__content-title {
        margin: -50px 0 20px 0;
      }
    }
    @include responsive() {
      align-self: baseline !important;
      width: 100%;
      padding: 0 30px 150px 80px;
      &:before {
        left: 10px !important;
        padding: 0 !important;
        top: 50px;
        text-align: center !important;
        width: 60px;
        border: none !important;
      }
      &:last-child {
        padding-bottom: 40px;
      }
    }
  }
  &__img {
    max-width: 100%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .4);
  }
  &-container {
    width: $timeline-container-width;
    position: relative;
    padding: 80px 0;
    transition: .3s ease 0s;
    background-attachment: fixed;
    background-size: cover;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(99, 99, 99, 0.8);
      content: "";
    }
  }
  &-header {
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    &__title {
      color: #fff;
      font-size: 46px;
      font-family: $content-font;
      font-weight: normal;
      margin: 0;
    }
    &__subtitle {
      color: rgba(255, 255, 255, .5);
      font-family: $heading-font;
      font-size: 16px;
      letter-spacing: 5px;
      margin: 10px 0 0 0;
      font-weight: normal;
    }
  }
}


</style>


<template>


<div class="about">


    <div class="about-content">

    <div class="timeline-container" id="timeline-1" ref="timeline-container">

      <div class="timeline-header">
        <h2 class="timeline-header__title">History of Carpio Music</h2>
        <h3 class="timeline-header__subtitle">Entertaining Hong Kong for 40 Years</h3>
      </div>
      <div class="timeline">

      <div class="timeline-item" :class="{ 'timeline-item--active': isInView(itemIndex) }" :ref="`timeline-item-${itemIndex}`" :data-text="item.tagline" v-for="(item, itemIndex) in timelineItems">
        <div class="timeline__content"><img class="timeline__img" :src="item.image"/>
          <h2 class="timeline__content-title">{{ item.year }}</h2>
          <p class="timeline__content-desc">{{ item.desc }}</p>
        </div>
      </div>

    <!--<div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://gazetemanifesto.com/wp-content/uploads/2015/11/mustafa-kemal.jpg"/>
        <h2 class="timeline__content-title">1893</h2>
        <p class="timeline__content-desc">First enrolled in a traditionalreligious school, he soon switched to a modern school. In 1893, he entered a military highschool where his mathematics teacher gave him the second name Kemal (meaning perfection)in recognition of young Mustafa's superior achievement.</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="https://upload.wikimedia.org/wikipedia/commons/2/20/Ataturk-1930-amongpublic.jpg"/>
        <h2 class="timeline__content-title">1905</h2>
        <p class="timeline__content-desc">In 1905, Mustafa Kemal graduated from the War Academy in Istanbul with the rank of Staff Captain. Posted in Damascus, he started with several colleagues, a clandestinesociety called "Homeland and Freedom" to fight against the Sultan'sdespotism.</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://cdn.yemek.com/uploads/2014/11/ataturk-10-kasim.jpg"/>
        <h2 class="timeline__content-title">1908</h2>
        <p class="timeline__content-desc">In 1908 he helped the group of officers who toppled the Sultan. Mustafa Kemal'scareer flourished as he won his heroism in the far corners of the Ottoman Empire,including Albania and Tripoli. He also briefly served as a staff officer in Salonica andIstanbul and as a military attache in Sofia.</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://ataturk.istanbul.gov.tr/GalleryLibrary/12.jpg"/>
        <h2 class="timeline__content-title">1915</h2>
        <p class="timeline__content-desc">In 1915, when Dardanelles campaign was launched, Colonel Mustafa Kemal became anational hero by winning successive victories and finally repelling the invaders.</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://blog.istanbul1881.com/wp-content/uploads/2016/08/atat%C3%BCrk-%C3%BCn-inan%C4%B1lmaz-karizmatik-fotograf%C4%B1_861050.jpg"/>
        <h2 class="timeline__content-title">1916</h2>
        <p class="timeline__content-desc">Promotedto general in 1916, at age 35, he liberated two major provinces in eastern Turkey thatyear. In the next two years, he served as commander of several Ottoman armies inPalestine, Aleppo, and elsewhere, achieving another major victory by stopping the enemyadvance at Aleppo.</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://manisanokta.com/wp-content/uploads/2014/07/ataturk-20.jpg"/>
        <h2 class="timeline__content-title">1919</h2>
        <p class="timeline__content-desc">On May 19, 1919, Mustafa Kemal Pasha landed in the Black Sea port of Samsun to startthe War of Independence. In defiance of the Sultan's government, he rallied a liberationarmy in Anatolia and convened the Congress of Erzurum and Sivas which established thebasis for the new national effort under his leadership.</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://www.volpeypir.com/upload/3732.jpg"/>
        <h2 class="timeline__content-title">1920</h2>
        <p class="timeline__content-desc">
          On April 23, 1920, the GrandNational Assembly was inaugurated. Mustafa Kemal Pasha was elected to its Presidency.
          Fighting on many fronts, he led his forces to victory against rebels and invadingarmies. Following the Turkish triumph at the two major battles at Inonu in Western Turkey,the Grand National Assembly conferred on Mustafa Kemal Pasha the title ofCommander-in-Chief with the rank of Marshal.
        </p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://sanatkaravani.com/wp-content/uploads/2015/11/ataturk-4.jpg"/>
        <h2 class="timeline__content-title">1922</h2>
        <p class="timeline__content-desc">At the end of August 1922, the Turkish armieswon their ultimate victory. Within a few weeks, the Turkish mainland was completelyliberated, the armistice signed, and the rule of the Ottoman dynasty abolished</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="https://turkcetarih.com/wp-content/uploads/2015/05/Atat%C3%BCrkveCumhuriyet.jpg"/>
        <h2 class="timeline__content-title">1923</h2>
        <p class="timeline__content-desc">In July 1923, the national government signed the Lausanne Treaty with Great Britain,France, Greece, Italy, and others. In mid-October, Ankara became the capital of the new Turkish State. On October 29, the Republic was proclaimed and Mustafa Kemal Pasha wasunanimously elected President of the Republic.</p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="https://pbs.twimg.com/media/Cw69H8pXUAEaSqa.jpg"/>
        <h2 class="timeline__content-title">1934</h2>
        <p class="timeline__content-desc">
          The account of Atatürk's fifteen year Presidency is a saga of dramatic modernization.With indefatigable determination, he created a new political and legal system, abolished the Caliphate and made both government and education secular, gave equal rights to women,changed the alphabet and the attire, and advanced the arts and the sciences, agricultureand industry.
          In 1934, when the surname law was adopted, the national parliament gave him the name <i><strong>"Atatürk" (Father of the Turks)</strong></i>
        </p>
      </div>
    </div>
    <div class="timeline-item" data-text="FATHER OF THE TURKS">
      <div class="timeline__content"><img class="timeline__img" src="http://www.bik.gov.tr/wp-content/uploads/2016/11/20161110_2_20037273_15856882.jpg?fit=1024%2C863"/>
        <h2 class="timeline__content-title">1938</h2>
        <p class="timeline__content-desc">On November 10, 1938, following an illness of a few months, the national liberator and the Father of modern Turkey died. But his legacy to his people and to the world endures.</p>
      </div>
    </div>-->
  </div>
</div>



      <div class="container">
      </div>
    </div>

    <MenuCompo :tiny="true" />

    <P9 />
  </div>
</template>

<script>

import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'

export default{
  components: { P9, MenuCompo },
  data () {
    return {
      timelineItems: [
        {
          tagline: 'The Founding',
          image: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F35369224.1554245-carpio-music-history-01.jpg?alt=media&token=43fe10ec-b28c-409b-87e2-c26ee264e543',
          year: '1977',
          desc: `Founded by Tony Carpio in 1977, Carpio Music (famously known as The Tony Carpio Music Workshops) delivered the first guitar seminars and Improvisational Jazz & Composition`
        },
        {
          tagline: 'Trainning & Development',
          image: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F16974506.821485337-carpio-music-history-02.jpg?alt=media&token=183b616e-040c-4b10-8e71-a483d1ad8a6f',
          year: '1978',
          desc: `In the 1970s, Carpio Music trained TVB stars and contest winners such as Danny Chan [Chan Bak Keung], Priscilla Chan [Chan Wai Han], Connie Mak [Mak Kit Man]`
        },
        {
          tagline: 'Opening of Recording Studio',
          image: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F17780394.302475557-carpio-music-history-03.jpg?alt=media&token=13b58105-1dc9-4949-9dcf-2f8813a75341',
          year: '1979',
          desc: `1979 saw the opening of "Studio A", a recording studio for Carpio Music to develop Jingles for TV Comercials, Soundtracks, and 2 further studio albums featuring the Tony Carpio Big Band: "Twelve Delicious Flavours, and "Jazz at the Dickens"`
        },
        {
          tagline: 'The Carpio Age',
          image: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F73591157.31771371-carpio-music-history-04.jpg?alt=media&token=d8c9787d-1e2e-4f2d-91fe-ec2d419f81ab',
          year: '1980',
          desc: `And In the 80s, Carpio Music began delivering performances at Corporate Dinners and Events for Hong Kong's most successful businesses at Maxim's Palace. Apart from these performances, Carpio Music performed alongside such international artists as Andy Williams, Patti Page, and Hong Kong's top talent including Anita Mui, Teresa Carpio, Kenny B, Elisa Chan, Louis Castro, Francis Yip, to name but a few`
        },
        {
          tagline: 'The Tony Carpio Big Band',
          image: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F25902149.683752216-carpio-music-history-05.jpg?alt=media&token=d52a56f4-59f4-4200-88a0-c4205ee15a5f',
          year: '1990',
          desc: `The Tony Carpio Combo Band and his Big Band have opened numerous 5-Star hotels including the Grand Hyatt Hong Kong, Regent Hotel, Conrad Hotel, Kowloon Shangri-La, Peninsula Hotel Shanghai, and has performed for many high profile events, such as: The 1997 Hand Over Ceremony, Hong Kong's hosting of The Louis Vuitton's 100th Anniversary, The Peninsula Hotel's 70th, 75th, and 85th Anniversary, Rotary Club Ball in Beijing, The Hong Kong Jockey Club's Invitation Cup, Launch Party for Dunhill XO in China and Taiwan; plus The Hong Kong Cancer Fund's Charity Balls, several AMCHAM Annual Dinners, Riding For The Disabled Fund Raising Dinner`
        },
        {
          tagline: 'The New Era',
          image: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F80124541.60902062-carpio-music-history-06.jpg?alt=media&token=dea4d5f6-9eeb-4a19-90f5-ec22cdd0d8b5',
          year: '2017',
          desc: `Carpio Music has entered a new era of Live Entertainment in Modern times. Not only do we provide exciting Live Entertainment, we are also a full service Entertainment group managing a portfolio of Artists and Bands – continuing our mission in bringing only the Best Live Entertainment to Hong Kong`
        }
      ],
      timelineItemsSizeInfo: [
      ]
    }
  },
  methods: {
    isInView (index) {
      let obj = this.timelineItemsSizeInfo[index] || { isInView: false }
      return (obj || {}).isInView
    }
  },
  mounted () {
    var onScroll = () => {
      var updateNeeded = false
      this.timelineItemsSizeInfo.forEach((item, itemIndex) => {
        let rect = this.$refs[`timeline-item-${itemIndex}`][0].getBoundingClientRect()
        let newIsInView = (window.innerHeight - rect.top) > (window.innerHeight / 100 * 50)
        if (item.isInView !== newIsInView) {
          item.isInView = newIsInView
          if (newIsInView) {
            this.$refs['timeline-container'].style.backgroundImage = `url("${this.timelineItems[itemIndex].image}")`
          }
          updateNeeded = true
        }
      })
      if (updateNeeded) {
        this.$forceUpdate()
      }
    }
    window.addEventListener('scroll', onScroll, false)

    setTimeout(() => {
      onScroll()
    }, 500)

    var onResize = () => {
      this.timelineItems.forEach((item, itemIndex) => {
        this.timelineItemsSizeInfo[itemIndex] = {}
      })
    }
    onResize()
    window.addEventListener('resize', onResize, false)

    this.clean = () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('scroll', onScroll)
    }
  },
  beforeDestroy () {
    this.clean()
  }
}
</script>
