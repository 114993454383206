<style lang="scss" scoped>
@import "../../layout.scss";

.contact-us {
  width: 100vw;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;

  background-color: #101010;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
}

@media (orientation: landscape) and (max-height: 500px) {
  .contact-us {
    min-height: 100vmax;
  }
}

.title {
  /* Artists: */
  font-family: PlayfairDisplay-Regular, "Playfair Display", serif;
  font-size: 36px;
  color: #ffffff;
  line-height: 44px;
}

.title-bar {
  width: 100%;

  display: flex;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
}
.resp-con {
  width: 280px;
}

.title-box {
  margin-top: 58px;
  height: 80px;
}

.contact-us-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-us-box {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.contact-us-con {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pre-padding {
  height: 50px;
}

.extra-padding {
  height: 0px;
}

.contact-desc {
  text-align: center;
  width: 266px;
  padding: 35px 0px;
}

.input-title {
  /* EMAIL: */
  font-family: AvenirNext-Regular;
  font-size: 14px;
  letter-spacing: 2px;
  color: #cecece;
  padding-bottom: 3px;
}

.select-style {
  // border: 1px solid white;
  min-width: 136px;
  height: 35px;
  border-radius: 0px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1)
    url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F94423312.63437107-downbtn.png?alt=media&token=4cd38735-a76e-4415-9b7b-957298c151d9")
    no-repeat 101% 50%;
  background-size: 50px 37px;
}

.select-style select {
  /* WEDDING: */
  font-family: AvenirNext-Regular;
  font-size: 16px;
  color: white;
  letter-spacing: 0px;
  line-height: 30px;

  padding: 3px 15px;
  width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  appearance: none;
}

.select-style select:focus {
  outline: none;
  appearance: none;
}

.select-trim {
  width: 75%;
  overflow: hidden;
}

.input-style {
  appearance: none;
  border: none;
  height: 30.46px;
  width: 106px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 3px 15px;
  margin: 0px;
  border-radius: 0px;

  /* WEDDING: */
  font-family: AvenirNext-Regular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0px;
  line-height: 30px;
}

.input-style:focus {
  outline: none;
  appearance: none;
}

.input-row {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.textarea-style {
  appearance: none;
  border: none;
  height: 121.85px;
  width: 280px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 10px;
  margin: 0px;

  /* WEDDING: */
  font-family: AvenirNext-Regular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
}

.textarea-style:focus {
  outline: none;
}

.send-bar {
  text-align: center;
  /* SEND: */
  font-family: AvenirNext-Regular;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  color: #d7a53a;
  letter-spacing: 2px;
  line-height: 40px;
  margin-bottom: 15px;
  width: 200px;
  appearance: none;
  border: none;
  box-shadow: none;
  margin-top: 16px;
  background-color: transparent;
}

.input-item.double {
  width: calc(100% - 0px);
  padding: 0px 25px;
}

@media (min-width: 1365px) {
  .input-item.double {
    padding: 0px 5px;
  }
}
@media (max-width: 450px) {
  .input-item.double {
    padding: 0px 10px;
  }
}

.select-style.double {
  width: 100%;
}

@media (min-width: 767px) {
  .contact-desc {
    width: 317px;
  }
  .resp-con {
    width: 600px;
  }
  .contact-us-box {
    width: 600px;
  }
  .contact-us-con {
    width: 524px;
  }
  .input-title {
    font-family: AvenirNext-Regular;
    line-height: 30px;
  }
  .input-style {
    width: 183px;
    height: 38px;
    /* REQUIRED: */
    font-family: AvenirNext-Regular;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 30px;
  }
  .select-style {
    width: calc(214px);
    height: calc(44px);
    background: rgba(255, 255, 255, 0.1)
      url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F86624244.83143209-downbtn2.png?alt=media&token=3e6e1870-cee6-4e23-a4c0-415124d99ce8")
      no-repeat 101% 50%;
  }

  .select-style select {
    /* REQUIRED: */
    font-family: AvenirNext-Regular;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0px;
    line-height: 30px;
    padding: 6px 15px;
  }
  .textarea-style {
    width: calc(475.86px - 20px);
    height: calc(176px - 20px);
  }
  .send-bar {
    width: calc(475.86px);
  }

  .extra-padding {
    height: 150px;
  }
}

@media (min-width: 1365px) {
  .contact-desc {
    width: 761px;
  }
  .resp-con {
    width: 907px;
  }
  .contact-us-box {
    width: 907px;
  }
  .contact-us-con {
    width: 761px;
  }
  .input-style {
    width: calc(368px - 30px);
    height: calc(44px - 6px);
  }
  .select-style {
    width: calc(368px);
    height: calc(44px);
    // background: rgba(215,165,58, 0.1) url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F86624244.83143209-downbtn2.png?alt=media&token=3e6e1870-cee6-4e23-a4c0-415124d99ce8") no-repeat 101% 50%;
  }
  .textarea-style {
    width: calc(752px - 12px);
    height: calc(176px - 20px);
  }
  .send-bar {
    width: calc(752px);
  }
}

@media (min-width: 321px) and (max-width: 766px) {
  .input-item {
    min-width: 43.33%;
  }
  .input-style {
    width: 38.73vw;
  }
  .textarea-style {
    width: 90vw;
  }
  .send-bar {
    width: 96vw;
  }
}

.linklink:visited,
.linklink:active,
.linklink:hover,
.linklink {
  color: white;
  text-decoration: underline;
}
</style>

<template>
  <div class="contact-us">
    <div class="pre-padding"></div>
    <div class="title-bar">
      <div class="resp-con">
        <div class="title-box">
          <div class="title">Contact us</div>
        </div>
      </div>
    </div>

    <form @submit.prevent="submit()" class="contact-us-wrap">
      <div class="contact-us-box">
        <div class="contact-us-con">
          <div class="contact-desc">
            Let us know about your event, and we’ll get back to you shortly.
            <br />
            <br />
            Our Office: 2714 9944
            <br />
            <!-- info@carpiomusic.com -->
            <span class="linkink" ref="email"></span>
          </div>
        </div>
      </div>
    </form>

    <div class="extra-padding"></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      sent: false,
      newDate: this.getDate(),
      newPaSysRequired: "no",
      newName: "",
      newEmail: "",
      newContactNumber: "",
      newTypeOfEvent: "",
      newTypeOfBand: "",
      newBandSize: "",
      newMessage: "",
      newLocation: "",
      newTime: "",
    };
  },
  computed: {
    bands() {
      return this.bandsSource.sort((a, b) => {
        return parseFloat(a.order) > parseFloat(b.order);
      });
    },
    ...mapGetters({
      // 'findWord': 'words/findWord',
      bandsSource: "bands/bands",
    }), // ,
    // word () {
    //   return (id) => {
    //     return this.findWord('band-list-page', id)
    //   }
    // }
  },
  mounted() {
    //
    // email
    var part1 = "chris";
    var part2 = Math.pow(2, 6);
    var part3 = String.fromCharCode(part2);
    var part4 = "carpiomusic.com";
    var part5 = part1 + String.fromCharCode(part2) + part4;
    this.$refs.email.innerHTML =
      'Please email us at <a class="linklink" style="color:white;" href=' +
      "mai" +
      "lto" +
      ":" +
      part5 +
      ">" +
      part1 +
      part3 +
      part4 +
      "</a>.";

    //
    //
    // this.$store.dispatch('words/loadWords', { useRealtime: false })
    this.$store.dispatch("bands/loadBands", {
      useRealTime: false,
      mode: "init",
    });
  },
  methods: {
    ...mapActions({
      addContactUs: "contacts/addContact",
    }),
    getDate(date) {
      var today = date || new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; // January is 0!

      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      return yyyy + "-" + mm + "-" + dd;
    },
    submit() {
      if (
        this.newName &&
        this.newEmail &&
        this.newContactNumber &&
        this.newTypeOfEvent &&
        this.newTypeOfBand &&
        // this.newBandSize &&
        this.newPaSysRequired &&
        this.newMessage &&
        this.newLocation
      ) {
        let datas = {
          date: this.newDate,
          time: this.newTime,

          name: this.newName,
          email: this.newEmail,
          contactNumber: this.newContactNumber,
          typeOfEvent: this.newTypeOfEvent,
          typeOfBand: this.newTypeOfBand,
          bandSize: this.newBandSize,
          paSysRequired: this.newPaSysRequired,
          location: this.newLocation,
          message: this.newMessage,
        };
        this.addContactUs(datas);
        // var bodyFormData = new FormData()
        // bodyFormData.set('Date', this.newDate)
        // bodyFormData.set('Time', this.newTime)
        // bodyFormData.set('Name', this.newName)
        // bodyFormData.set('_replyto', this.newEmail)
        // bodyFormData.set('ContactNumber', this.newContactNumber)
        // bodyFormData.set('TypeOfEvent', this.newTypeOfEvent)
        // bodyFormData.set('TypeOfBand', this.newTypeOfBand)
        // bodyFormData.set('BandSize', this.newBandSize)
        // bodyFormData.set('PASystemRequired', this.newPaSysRequired)
        // bodyFormData.set('Location', this.newLocation)
        // bodyFormData.set('message', this.newMessage)
        // bodyFormData.set('submit', 'Send')

        axios({
          method: "post",
          url: "https://mailthis.to/chriscarpio1@gmail.com",
          data: {
            _subject: "New contact form email:" + this.newName,
            ...datas,
          }, // ,
          // config: {
          //   headers: {
          //     'Content-Type': 'multipart/form-data'
          //   }
          // }
        })
          .then((response) => {
            this.sent = true;
            location.href = "https://mailthis.to/confirm";
            // handle success
            console.log(response);
          })
          .catch((response) => {
            // handle error
            console.log(response);
          });
      }
    },
  },
};
</script>
