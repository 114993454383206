<style lang="scss" scoped>
.back-btn{
  color: white;
}
</style>


<template>

  <ItemDetail :info="currentClient" :subtitle="'Thank you Letter'">
    <MenuCompo :tiny="true" slot="nav" />
    <P9 slot="footer" />
    <router-link tag="span" to="/clients" slot="back-btn" class="back-btn">← Back</router-link>
  </ItemDetail>

</template>

<script>
import { mapGetters } from 'vuex'

import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'
import ItemDetail from '@/components/Reusable/ItemDetail'

export default {
  components: {
    P9, MenuCompo, ItemDetail
  },
  name: 'client-detail',
  data () {
    return {
    }
  },
  computed: {
    headerStyle () {
      return {
        backgroundImage: `url(${this.heroImg})`
      }
    },
    heroImg () {
      return this.currentClient.heroImgSrc || ''
    },
    currentClient () {
      var curClinet = this.clients.filter((client) => {
        return client.name.toUpperCase().split(' ').join('-').trim() === this.$route.params.bid.toUpperCase().trim()
      })[0] || {}

      curClinet.heroImgSrc = curClinet.heroImgSrc || (curClinet.heroImgSrc = `https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F96307276.98237738-clients_header_carpiomusic.jpg?alt=media&token=48fe9172-9c03-4aad-ad07-9c6a36a63e47`)

      return curClinet
    },
    word () {
      return (id) => {
        return this.findWord('client-list-page', id)
      }
    },
    ...mapGetters({
      'findWord': 'words/findWord',
      'clients': 'clients/clients'
    })
  },
  mounted () {
    // this.$store.dispatch('words/loadWords', { useRealtime: !!this.$store.state.user })
    this.$store.dispatch('clients/loadClients', { useRealTime: false, mode: 'init' })
  }
}
</script>
