import Vue from 'vue'
import Vuex from 'vuex'
import firebase from '@/firebase'
import router from '@/router'
import pages from '@/store/pages'
import galleries from '@/store/galleries'
import youtubes from '@/store/youtubes'
import events from '@/store/events'
import words from '@/store/words'
import contacts from '@/store/contacts'
import artists from '@/store/artists'
import bands from '@/store/bands'
import clients from '@/store/clients'

import { firebaseMutations } from 'vuexfire'

Vue.use(Vuex)

var state = {
  loading: false,
  user: null
}

var mutations = {
  ...firebaseMutations,
  setUser (state, value) {
    state.user = value
  }
}

var getters = {
  currentUser (state) {
    return state.user
  }
}

var actions = {
  login ({ dispatch, commit }) {
    var email = window.prompt('email?')
    var password = window.prompt('password?')
    firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
      dispatch('checkLogin')

      // Handle Errors here.
      var errorCode = error.code
      var errorMessage = error.message
      console.log(errorCode, errorMessage)
    })// login
  },
  logout ({ dispatch, commit }) {
    firebase.auth().signOut().then(() => {
      dispatch('checkLogin')
    }).catch((error) => {
      dispatch('checkLogin')
      console.log(error)
    })
  },
  checkLogin ({ state }) {
    if (state.user && router.currentRoute.path === '/cms') {
      // router.replace('/cms')
    } else if (!state.user && router.currentRoute.path !== '/cms') {
      router.replace('/cms')
    }
  }
}

var onAuthChange = store => {
  firebase.auth().onAuthStateChanged((user) => {
    store.commit('setUser', user || false)
  })// check auth
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [onAuthChange]
})

export default store

pages.use(store)
galleries.use(store)
youtubes.use(store)
events.use(store)
words.use(store)
contacts.use(store)
artists.use(store)
bands.use(store)
clients.use(store)
