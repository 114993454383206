<style lang="scss" scoped>
@import "../../layout.scss";

.video{
  overflow: hidden;
  background-color: black;
}
.title{
  text-align: center;
  font-family: PlayfairDisplay-Regular, 'Playfair Display', serif;
  font-size: 36px;
  color: #FFFFFF;
  line-height: 44px;
  padding-bottom: 30px;
}

.padding-top{
  padding-top: 120px;
}
.padding-bottom{
  padding-bottom: 160px;
}

@media (max-width: 500px) {
  .padding-top{
    padding-top: 70px;
  }
  // .padding-bottom{
  //   padding-bottom: 80px;
  // }
}


.video-left{
  left: -115%;
}
.video-right{
  right: -115%;
}

.video-layout{
  width: 100vw;
  height: calc(56vw + 0px );
  // background-color: white;
}
@media (min-width: 1200px) {
  .video-layout{
    width: 1200px;
    height: calc( (1200px) * 0.56 + 0px );
  }
}
.video-iframe{
  width: 100%;
  height: calc(100% - 0px);
}

.arrow-left{
  left: calc(5%);
  width: 10%;
  max-width: 40px;
}
.arrow-right{
  right: calc(5%);
  width: 10%;
  max-width: 40px;
}
// @media (max-width: 769px) {
//   .arrow-right,
//   .arrow-left{
//     top: 35%;
//   }
// }

.arrow{
  transition: opacity 1s;
  opacity: 0;
}
.video-slider:hover .arrow{
  opacity: 1;
}

.is-touched-once{
  .arrow{
    opacity: 1;
  }
}


.subtitle{
  width: 100%;
  color: #ffffff;
  text-align: center;
  position: absolute;
  bottom: -80px;
  height: 80px;
}

.sub-title h3{
  line-height: 30px;
}

.video-cover{
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  .video-play{
    width: 5%;
  }
}

.video-slider{
  overflow-x: hidden;
  overflow-y: visible;
  overflow: visible;

  display: flex;
  justify-content: center;
  align-items: center;

  .video-frame{
    height: 100%;
  }

  .video-left,
  .video-right,
  .video-center,
  .video-center-inner{
    transition: opacity 1s, transform 1s;
  }

  &.show-center{
    .video-center {
      opacity: 1;
    }
    .video-left {
      opacity: 0;
    }
    .video-right {
      opacity: 0;
    }
  }
  &.show-left{
    .video-center {
      .video-center-inner {
        opacity: 0;
      }
      transform: translateX(-115%);
    }
    .video-left {
      opacity: 0;
    }
    .video-right {
      opacity: 1;
    }
  }

  &.show-right{
    .video-center {
      .video-center-inner {
        opacity: 0;
      }
      transform: translateX(115%);
    }
    .video-left {
      opacity: 1;
    }
    .video-right {
      opacity: 0;
    }
  }
}

</style>

<template>
  <div class="video padding-bottom">
    <div class="title padding-top">
      Videos
    </div>

    <div class="video-slider layout-fs layout-rp" :class="{
      'show-left': lookAt === 1,
      'show-right': lookAt === -1,
      'show-center': lookAt === 0,
    }">


      <div class="video-center layout-rp video-layout ">

        <div class="video-left layout-ap">
          <div class="video-layout layout-rp">
            <div @click="() => { playing['-1'] = !playing['-1'] }" class="video-cover video-layout" :style="`background-image: url(${word('left-cover')})`" v-if="!playing['-1']">
              <img class="video-play" src="./img/play_button1_nice.png" />
            </div>
            <iframe class="video-layout d-video-iframe" :src="getEmbedLink(word('left'), true)" v-if="playing['-1']" frameborder="0" allowfullscreen></iframe>
            <div class="subtitle">
              <h3>{{ word('left-desc') }}</h3>
            </div>
          </div>
        </div>

        <div class="video-right layout-ap">
          <div class="video-layout layout-rp">
            <div @click="() => { playing['1'] = !playing['1'] }" class="video-cover video-layout" :style="`background-image: url(${word('right-cover')})`" v-if="!playing['1']">
              <img class="video-play" src="./img/play_button1_nice.png" />
            </div>
            <iframe class="video-layout d-video-iframe" :src="getEmbedLink(word('right'), true)" v-if="playing['1']" frameborder="0" allowfullscreen></iframe>
            <div class="subtitle">
              <h3>{{ word('right-desc') }}</h3>
            </div>
          </div>
        </div>

        <div class="video-center-inner video-layout layout-fs  layout-rp">
          <div @click="() => { playing['0'] = !playing['0'] }" class="video-cover video-layout" :style="`background-image: url(${word('center-cover')})`" v-if="!playing['0']">
              <img class="video-play" src="./img/play_button1_nice.png" />
          </div>
          <iframe class="video-layout d-video-iframe" :src="getEmbedLink(word('center'), true)" v-if="playing['0']" frameborder="0" allowfullscreen></iframe>
          <div class="subtitle">
            <h3>{{ word('right-desc') }}</h3>
          </div>
        </div>

      </div>

      <img class="arrow arrow-left layout-ap" src="./img/left_gold.png" v-if="lookAt !== -1" @click="lookAt = lookAt - 1" />
      <img class="arrow arrow-right layout-ap" src="./img/right_gold.png" v-if="lookAt !== 1" @click="lookAt = lookAt + 1" />

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'P3',
  data () {
    return {
      playing: {
        '-1': false,
        '0': false,
        '1': false
      },
      lookAt: 0
    }
  },
  computed: {
    ...mapGetters({
      'findWord': 'words/findWord'
    }),
    word () {
      return (id) => {
        return this.findWord('home-video-links', id)
      }
    }
  },
  methods: {
    getEmbedLink (url, autoplay) {
      let vid = ''

      if (url.indexOf('https://youtu.be/') !== -1) {
        url = url.replace('https://youtu.be/', '')
        if (url.indexOf('?t=') !== -1) {
          let arr = url.split('?t=')
          vid = arr[0]
        } else {
          vid = url
        }
      } else if (url.indexOf('https://www.youtube.com/watch?v=') !== -1) {
        vid = url.replace('https://www.youtube.com/watch?v=', '')
      }
      if (!vid) {
        return false
      } else {
        return `https://www.youtube.com/embed/${vid}?rel=0${autoplay ? '&autoplay=1' : ''}`
      }
    }
  }
}
</script>
