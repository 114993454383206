<style lang="scss" scoped>

.clients-body {
    background: #101010;
    background-image: url("");

}

p {
    color: #ffffff;
    line-height: 28px;
}
h1 {
  font-weight: 100;
}
h2 {
    font-family: 'Avenir', sans-serif;
    line-height: 28px;
}

.title-h1{
  text-align: left;
  // font-size: 36px;
  color: white;
  line-height: 44px;

  font-family: 'Playfair Display', serif;
  font-size: 34px;
  // color: #000;
  // margin-top: 10vh;
  font-weight: 100;
  // padding-bottom: 30px;
  // margin-top: 31px;
}

.padding {
    padding: 40px 0;
}

.divider {
    border-bottom: 1px solid #523d00;
    width: 300px;
    margin: 0 auto;
}

.sub-head {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
}
.header{
  width: 100%;
  height: 41.06vw;
  // max-height: 50vh;
  background-size: cover;
  // height: 155px;
  // background-size: 415px 155px;
  background-position: top center;
  background-color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F96307276.98237738-clients_header_carpiomusic.jpg?alt=media&token=48fe9172-9c03-4aad-ad07-9c6a36a63e47");
  // background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title-bg{
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.4);
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-title{
   font-family: 'Playfair Display', serif;
   color: #EAB749;
   font-size: 28px;
   z-index: 10;
}

.flex-container {
    display: flex;
    flex-direction: column;
    max-width: 1190px;
    // background-color:#EAB749;
    margin: 0 auto;
    align-items: center;
    padding: 30px 0;
}
.container {
    padding:8%;
    max-width: 1200px;
    margin: 0 auto;
}


.client-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.client-items{
  position: relative;
  cursor: pointer;
  width: 90%;
  background-color: #d8a943;
  background: linear-gradient(45deg, #d8a943, #d8b943);
  color: black;
  font-size: 21px;
  margin: 20px 0px;
  padding: 21px 21px 21px 41px;
}

.client-items:before{
  content: "";
  display: block;
  height: 100%;
  width: 25px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
  background: silver;
}

.clients-holder {
  width:100%;
}
.clients-holder img{
  width:95%;
}
.clients-holder-lg{
  width:100%;
  display: none;
}
.clients-holder-lg img{
  width:95%;
}



@media (min-width: 767px) {

}

@media (min-width: 1365px) {
    .header-title {
        font-size:42px;
    }
    .clients-holder{
      display: none;
    }
    .clients-holder-lg{
      display: block;
    }
}

@media (min-width: 1439px) {

}
</style>


<template>
<div class="clients-body">

    <div class="header">
      <div class="header-title-bg">
        <h1 class="header-title">Clients</h1>
      </div>
    </div>

    <div class="container">
        <h1 class="title-h1">
          Clients
        </h1>
        <p>Carpio Music's enterprise clients include all types of clients from different sectors. </p>
        <div class="clients-holder">
          <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F13261188.61268586-carpiomusicclients-02.png?alt=media&token=05e82685-b647-451f-ba86-df7b1a10e80c">
        </div>
        <div class="clients-holder-lg">
          <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F27719636.819829408-carpiomusic-clients01.png?alt=media&token=b19d0cdc-e5f7-4911-a1ad-441e3c5d17f3">
        </div>

        <br />
        <br />

        <h1 class="title-h1">
          Testimonial
        </h1>
        <div>
            <ul class="client-list">
                <li class="client-items" v-for="client in clients" @click="goDetail(client)">
                    {{ client.name }}
                </li>
            </ul>
        </div>
    </div>

    <div class="container">
    </div>


  <MenuCompo :tiny="true" />
  <P9 />

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'

export default {
  components: { P9, MenuCompo },
  data () {
    return {

    }
  },
  computed: {
    clients () {
      return this.clientsSource.sort((a, b) => {
        return parseFloat(a.order) > parseFloat(b.order)
      })
    },
    ...mapGetters({
      'findWord': 'words/findWord',
      'clientsSource': 'clients/clients'
    }),
    word () {
      return (id) => {
        return this.findWord('client-list-page', id)
      }
    }
  },
  mounted () {
    this.$store.dispatch('words/loadWords', { useRealTime: false })
    this.$store.dispatch('clients/loadClients', { useRealTime: false, mode: 'init' })
  },
  methods: {
    goDetail (client) {
      this.$router.push({
        path: `/clients/${client.name.toLowerCase().split(' ').join('-')}`
      })
    }
  }
}
</script>

