const namespace = 'galleries'
export const ns = namespace
export const pfx = ns + '/'
import { galleries } from '@/firebase'
import { firebaseAction } from 'vuexfire'
// const codebase = db.ref('codebase')

const state = {
  realtimeGalleries: [],
  staticGalleries: [],
  galleryMode: 'staticGalleries',
  currentGalleryIndex: 0
}

const getters = {
  galleries (state) {
    return state[state.galleryMode] || []
  },
  currentGallery (state, getters) {
    var galleries = getters.galleries
    if (typeof galleries[0] !== 'undefined') {
      return galleries[state.currentGalleryIndex]
    } else {
      return false
    }
  }
}

const mutations = {
  setCurrentGalleryIndex (state, index) {
    state.currentGalleryIndex = index
  },
  clearRealtimeGalleries (state) {
    state.realtimeGalleries = []
  },
  useRealtimeGalleries (state) {
    state.galleryMode = 'realtimeGalleries'
  },
  saveStaticGalleries (state, staticGalleries) {
    if (staticGalleries) {
      state.staticGalleries = Object.keys(staticGalleries).map((key, index) => {
        return {
          '.key': key,
          ...staticGalleries[key]
        }
      })
    } else {
      state.staticGalleries = []
    }
  }
}

const actions = {
  loadGalleries ({ dispatch }, { useRealtime }) {
    if (useRealtime) {
      dispatch('loadRealtimeGalleries')
    } else {
      dispatch('loadStaticGalleries')
    }
  },
  loadStaticGalleries ({ state, commit }) {
    galleries.once('value').then((snapshot) => {
      commit('saveStaticGalleries', snapshot.val())
    })
  },
  loadRealtimeGalleries: firebaseAction(function ({ state, commit, unbindFirebaseRef, bindFirebaseRef }) {
    commit('useRealtimeGalleries')
    if (typeof state.realtimeGalleries[0] !== 'undefined') {
      commit('clearRealtimeGalleries')
      unbindFirebaseRef('realtimeGalleries')
    }
    bindFirebaseRef('realtimeGalleries', galleries)
  }),
  addGallery ({ state }, data) {
    return galleries.push({
      title: 'My New Gallery',
      ...data
    })
  },
  removeGallery ({ state }, gallery) {
    if (window.confirm('Delete?')) {
      galleries.child(gallery['.key']).remove()
    }
  },
  saveGallery ({ state }, gallery) {
    let newObj = { ...gallery }
    delete newObj['.key']
    galleries.child(gallery['.key']).set(newObj)
  }
}

const moduleObj = {
  mutations,
  ns,
  pfx,
  namespaced: true,
  state,
  actions,
  getters,
  use,
  clean
}

function use (store) {
  store.registerModule(ns, moduleObj)
  // @___@'v
  //

  // store.subscribe((mutation, state) => {
  //   // called after every mutation.
  //   // The mutation comes in the format of `{ type, payload }`.
  // })
}

function clean (store) {
  store.unregisterModule(ns)
}

export default moduleObj
