const namespace = "words";
export const ns = namespace;
export const pfx = ns + "/";
import { words } from "@/firebase";
import { firebaseAction } from "vuexfire";
// const codebase = db.ref('codebase')

const state = {
  realtimeWords: [],
  staticWords: [],
  wordMode: "staticWords",
  currentWordIndex: 0,
};

const getters = {
  words(state) {
    return state[state.wordMode] || [];
  },
  currentWord(state, getters) {
    var words = getters.words;
    if (typeof words[0] !== "undefined") {
      return words[state.currentWordIndex];
    } else {
      return false;
    }
  },
  findWordCollection: (state, getters) => (collectionID) => {
    return (
      state[state.wordMode].filter((word) => {
        return word.title === collectionID;
      }) || []
    );
  },
  findWord: (state, getters) => (collectionID, pairID) => {
    return (
      (
        (getters.findWordCollection(collectionID)[0] || { wordPair: [] })
          .wordPair || []
      ).filter((item) => {
        return item.id === pairID;
      })[0] || { words: "" }
    ).words;
  },
};

const mutations = {
  setCurrentWordIndex(state, index) {
    state.currentWordIndex = index;
  },
  clearRealtimeWords(state) {
    state.realtimeWords = [];
  },
  useRealtimeWords(state) {
    state.wordMode = "realtimeWords";
  },
  saveStaticWords(state, staticWords) {
    if (staticWords) {
      state.staticWords = Object.keys(staticWords).map((key, index) => {
        return {
          ".key": key,
          ...staticWords[key],
        };
      });
    } else {
      state.staticWords = [];
    }
  },
};

const actions = {
  loadWords({ dispatch }, { useRealtime }) {
    if (useRealtime) {
      dispatch("loadRealtimeWords");
    } else {
      dispatch("loadStaticWords");
    }
  },
  loadStaticWords({ state, commit }) {
    words.once("value").then((snapshot) => {
      commit("saveStaticWords", snapshot.val());
    });
  },
  loadRealtimeWords: firebaseAction(function ({
    state,
    commit,
    unbindFirebaseRef,
    bindFirebaseRef,
  }) {
    commit("useRealtimeWords");
    if (typeof state.realtimeWords[0] !== "undefined") {
      commit("clearRealtimeWords");
      unbindFirebaseRef("realtimeWords");
    }
    bindFirebaseRef("realtimeWords", words);
  }),
  addWord({ state }, data) {
    return words.push({
      title: "My New Word",
      ...data,
    });
  },
  removeWord({ state }, word) {
    if (window.confirm("Delete?")) {
      words.child(word[".key"]).remove();
    }
  },
  saveWord({ state }, word) {
    let newObj = { ...word };
    delete newObj[".key"];
    words.child(word[".key"]).set(newObj);
  },
};

const moduleObj = {
  mutations,
  ns,
  pfx,
  namespaced: true,
  state,
  actions,
  getters,
  use,
  clean,
};

function use(store) {
  store.registerModule(ns, moduleObj);
  // @___@'v
  //

  // store.subscribe((mutation, state) => {
  //   // called after every mutation.
  //   // The mutation comes in the format of `{ type, payload }`.
  // })
}

function clean(store) {
  store.unregisterModule(ns);
}

export default moduleObj;
