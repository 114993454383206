<style lang="scss" scoped>
    .contact-body {
    background: #101010;
    background-image: url("");

}

p {
    color: #ffffff;
    line-height: 28px;
}
h1 {
  font-weight: 100;
}
h2 {
    font-family: 'Avenir', sans-serif;
    line-height: 28px;

}

.padding {
    padding: 40px 0;
}

.divider {
    border-bottom: 1px solid #523d00;
    width: 300px;
    margin: 0 auto;
}

.sub-head {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
}
.header{
  width: 100%;
  height: 41.06vw;
  // max-height: 50vh;
  background-size: cover;
  // height: 155px;
  // background-size: 415px 155px;
  background-position: top center;
  background-color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F96307276.98237738-clients_header_carpiomusic.jpg?alt=media&token=48fe9172-9c03-4aad-ad07-9c6a36a63e47");
  // background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title-bg{
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.4);
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-title{
   font-family: 'Playfair Display', serif;
   color: #EAB749;
   font-size: 28px;
   z-index: 10;
}

.flex-container {
    display: flex;
    flex-direction: column;
    max-width: 1190px;
    // background-color:#EAB749;
    margin: 0 auto;
    align-items: center;
    padding: 30px 0;
}
.container {
    padding:8%;
    max-width: 1200px;
    margin: 0 auto;
}


@media (min-width: 767px) {


}

@media (min-width: 1365px) {
    .header-title {
        font-size:42px;
    }

}

@media (min-width: 1439px) {




}

</style>

<template>
  <div class="contact-body">



    <!-- <div class="header">
      <div class="header-title-bg">
        <h1 class="header-title"></h1>
      </div>
    </div> -->


    <!-- <div class="container">
        <p>Want to book an event or an enquiry? </p>
    </div> -->

<P8 />
    <div class="flex-container">
    </div>


  <MenuCompo :tiny="true" />
  <P9 />

</div>







</template>



<script>

import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'
import P8 from '@/components/Home/P8'

export default {
  components: { P9, MenuCompo, P8 }
}
</script>
