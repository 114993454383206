<template>
  <div class="learn-more-box">
    <router-link tag="div" :to="to" class="learn-more">LEARN MORE</router-link>
  </div>
</template>

<script>
export default {
  props: ['to']
}
</script>

<style lang="scss" scoped>
.learn-more{
  cursor: pointer;
  width: 173px;
  height: 52px;
  /* Rectangle 4: */
  background: #000000;
  // border-radius: 100px;
  /* Learn More: */
  font-family: Raleway-Bold, 'Raleway', sans-serif;
  font-size: 11px;
  color: #FFFFFF;
  letter-spacing: 1.56px;
  // line-height: 21px;

  text-align: center;
  line-height: 52px;
}

.learn-more-box{
  width: 100%;
  height: 110px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
