<style lang="scss" scoped>
@import "../../layout.scss";


p {
    font-family: 'Nunito', sans-serif;
    color: #000000;
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;
}

h1 {
    font-family: 'Playfair Display', serif;
    font-size: 34px;
    color: #000;
    margin-top: 10vh;
    font-weight: 100;
}


.flex-container {
display: flex;
    flex-direction: column;
    margin-left:10vw;
    margin-right:10vw;
    // padding-bottom: 50px;
    padding-top: 50px;
}

.strong {
  font-weight:700;
}

.text-ctr {
  text-align: center;
  color: #929292;
  margin-top: 75px;
}

.divider {
  border-bottom: #cccccc 1px solid;

  margin: 0 auto;
  width: 50%;
}




.bands{
  width: 100vw;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;

  background-color: white;
  background-repeat: repeat repeat;
  background-position: center center;
}

@media (orientation: landscape) and (max-height: 500px) {
  .bands{
    min-height: 100vmax;
  }
}


.resp-con{
  width: 288px;
}

.title {
  /* Artists: */
  font-family: PlayfairDisplay-Regular, 'Playfair Display', serif;
  font-size: 36px;
  color: #000000;
  line-height: 44px;
}

.title-bar{
  width: 100%;
  display: flex;
  justify-content: center;
}

.title-box{
  margin-top: 108px;
  height: 100px;


}

.yellow-line{
  width: 107px;
  height: 3px;
  background-color: #F6CD45;
  margin-top: 9px;
  margin-left: -2px;
}


.band-box-wrap{
  width: 100%;
}
.resp-con.band-box-con{
  width: 100%;
}

.band-box{
  height: 154px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  cursor: pointer;
}
.band-txt{
  /* The Carpio Music Pre: */
  text-align: center;
  font-family: PlayfairDisplay-Regular, 'Playfair Display', sans-serif;
  color: #D7a53a;
  max-width: 300px;
  z-index: 2;

  opacity: 0;
  transition: opacity 1s;
}
.band-box:hover .band-txt{
  opacity: 1;
}

.is-touched-once{
   .band-txt{
    opacity: 1;
  }
}

.band-dimmer{
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.band-box:hover .band-dimmer{
  opacity: 1;
}

.is-touched-once{
  .band-dimmer{
    opacity: 1;
  }
}

.band-box-bg{
  width: 101%;
  height: 101%;
  top: 0px;
  left: 0px;
  background-size: cover;
  background-position: center;
  transition: transform 1s;
}
.band-box:hover .band-box-bg{
  transform: scale(1.1);
}


.learn-more{
  cursor: pointer;
  width: 173px;
  height: 52px;
  /* Rectangle 4: */
  background: #000000;
  font-family: Raleway-Bold, 'Raleway', sans-serif;
  font-size: 11px;
  color: #FFFFFF;
  letter-spacing: 1.56px;
  // line-height: 21px;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  text-align: center;
  line-height: 52px;
  transition-property: background-color;
}

.learn-more:hover {
  background-color: #D7a53A;
}

.learn-more-box{
  width: 100%;
  height: 110px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 767px) {
  .resp-con{
    width: 600px;
  }


  .band-box-wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 80px 0px;
  }
  .band-box-con{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .resp-con.band-box-con{
    width: 1024px;
  }

  .band-txt{
    /* The Carpio Music Pre: */
    font-size: 30px;
  }

  .band-box{
    width: 50%;
    flex-basis: 50%;
    height: 473px;
  }
  .band-box-1{
    transform: translateY(-10%);
  }
  .band-box-2{
    transform: translateY(10%);
  }

}

@media (min-width: 1335px){
  .resp-con{
    width: 907px;
  }
  .band-box-con{
    width: 600px;
  }
}


.annotation{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0px 30px 0px;
}

</style>

<template>
  <div class="bands layout-rp" :style="`background-image: url(${word('golden-texture')});`">
    <!--<div class="title-bar">
      <div class="resp-con">
        <div class="title-box">
          <div class="title">Bands</div>
          <div class="yellow-line"></div>
        </div>
      </div>
    </div>-->

    <!--<div class="annotation">
      <div class="resp-con">
        {{ word('desc') }}
      </div>
    </div>-->


    <div class="flex-container">
            <div class="divider"></div>

                <h2 class="text-ctr">Our Bands</h2>
              </div>


    <div class="band-box-wrap">
      <div class="resp-con band-box-con">


        <router-link tag="div" :to="word('band-1-link')" class="band-box band-box-1 layout-rp">
          <div class="band-box-bg box-bg-1 layout-ap" :style="`background-image: url(${band1pic})`"></div>
          <div class="band-dimmer layout-ap"></div>
          <div class="band-txt">{{ word('band-1-name') }}</div>
        </router-link>
        <router-link tag="div" :to="word('band-2-link')" class="band-box band-box-2 layout-rp">
          <div class="band-box-bg box-bg-2 layout-ap" :style="`background-image: url(${band2pic})`"></div>
          <div class="band-dimmer layout-ap"></div>
          <div class="band-txt">{{ word('band-2-name') }}</div>
        </router-link>

      </div>
    </div>

    <div class="learn-more-box">
      <router-link tag="div" to="/bands" class="learn-more">MORE BANDS</router-link>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'P5',
  computed: {
    band1pic () {
      return window.innerWidth < 500 ? this.word('band-pic-1@1x') : this.word('band-pic-1@2x')
    },
    band2pic () {
      return window.innerWidth < 500 ? this.word('band-pic-2@1x') : this.word('band-pic-2@2x')
    },

    ...mapGetters({
      'findWord': 'words/findWord'
    }),
    word () {
      return (id) => {
        return this.findWord('home-bands', id)
      }
    }
  }
}
</script>
