var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live-entertainment-body"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"padding divider"}),_c('div',{staticClass:"padding"}),_vm._m(3),_c('div',{staticClass:"padding divider"}),_c('div',{staticClass:"padding"}),_vm._m(4),_c('div',{staticClass:"padding divider"}),_c('div',{staticClass:"padding"}),_vm._m(5),_c('div',{staticClass:"padding"}),_c('MenuCompo',{attrs:{"tiny":true}}),_c('P9')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-title-bg"},[_c('h1',{staticClass:"header-title"},[_vm._v("Live Entertainment")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',{staticClass:"sub-head"},[_vm._v("Carpio Music has been providing rich live entertainment for a variety of personal, business and prestegious events for Over 40 years in Hong Kong. ")]),_c('p',[_vm._v("Wherever the venue, whichever the style – We bring events to life through good performance and music. Complimented with our State-of-the-art audio equipment, We love giving your events the highest quality live audio so your audiences and attendees can finally enjoy themselves.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"box-1"},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F1643474.9198857457-carpio-music-corporate1.jpg?alt=media&token=fe412e06-c2a7-4561-9259-60f7cdd0b4e4"}})]),_c('div',{staticClass:"box-2"},[_c('h2',[_vm._v("Corporate Events")]),_c('p',[_vm._v("Hong Kong's most comprehensive corporate entertainment choice.")]),_c('p',[_vm._v("Unlike a recording, Live Music can dictate the mood of an audience, depending on the focus of the event, whether it be a Launch Party, Fundraising Dinner, Listing Dinner or Company Anniversary. Impress your clients by letting us create the perfect atmosphere. We adjust our repertoire depending on needs covering Asian and Western favorites.")]),_c('p',[_vm._v("Whether you need a background band or a show, we can add excitement and anticipation for your company's event.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"box-3"},[_c('h2',[_vm._v("Weddings")]),_c('p',[_vm._v("For over 35 years we have been providing great entertainment to Weddings held at all venues. Our band's sought-after blend of a variety of music and live-entertainment caters for every type of bride. We also provide adaptable formats such as jazz, Funk, disco to suit the needs of every wedding.")])]),_c('div',{staticClass:"box-4"},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F50768607.12331168-Weddings-01-carpio-brothers.jpg?alt=media&token=4b68c2af-93ae-4714-bf93-6e1477893782"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"box-1"},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F96358001.45462415-carpio-music-private-events.png?alt=media&token=51ca5ea8-96c8-4047-91aa-8c87d8ac8b9f"}})]),_c('div',{staticClass:"box-2"},[_c('h2',[_vm._v("Private Events")]),_c('p',[_vm._v("Birthdays, Anniversaries, Annual Dinners, New Years' Eve Celebrations and the like. You name them and we've done them, from a Star Wars theme Annual Dinner, Great Gatsby style Birthday to Romantic French setting Anniversaries. ")]),_c('p',[_vm._v("Every musical detail will be there to set the vibe and ensure your party is a big success. Whether you're looking for a relaxed atmosphere or a high energy dance fest, your happiness is our number one priority.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"box-3"},[_c('h2',[_vm._v("In-House Entertainment")]),_c('p',[_vm._v("Carpio Music does not simply bring musicians to your venue, our expertise is finding well rounded artists and continuously growing their abilities to perform for your audience. ")]),_c('p',[_vm._v("With the ever changing pop culture, our bands play a wide range of classics and modern hits. To guarantee customer satisfaction, we program our artists music to ensure the entertainment matches the venues environment (Hotels, Bars, Restaurants, Clubs) ")])]),_c('div',{staticClass:"box-4"},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F59794701.97863526-carpio-music-inhouse-events.png?alt=media&token=e037b2b9-2ece-4ec3-81b4-8ff36f5bf277"}})])])
}]

export { render, staticRenderFns }