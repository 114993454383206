<style lang="scss" scoped>
.bands{

  background-color: #101010;
}

h1 {
  font-weight: 100;
}
.header{
  position: relative;
  width: 100%;
  height: 37.35vw;
  background-size: contain;
  // height: 155px;
  // background-size: 415px 155px;
  // background-position: top center;
  background-color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F55634957.46865819-bands_heading.jpg?alt=media&token=3cc757bb-d876-4ec7-87f7-9e6399b42cf2");
  background-position: center top;
  background-repeat: no-repeat no-repeat;
}

.desc{
  width: 100%;
  height: 161px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
}
.desc-txt{
  width: 258.5px;
}

.pics-con{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pics{
  max-width: 325px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.pic{
  margin-top: 20px;

  width: 325px;
  max-width: 325px;
  flex-basis: 325px;
  height: 117px;
  position: relative;
}
.pic:last-child{
  // margin-bottom: 20px;
}
.pic-img{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.pic-desc{
  // opacity: 0;
  transition: opacity 1s ease;
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  top: 0px;
  left: 0px;

  width: 325px;

  height: 118px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pic:hover {
  .pic-desc{
    // opacity: 1;
  }
}

.pic-desc-name{
  /* Tony Carpio Director: */
  font-family: PlayfairDisplay-Regular;
  font-size: 24px;
  line-height: 33px;
  color: #F6CD45;
}
.pic-desc-title{
  font-family: AvenirNext-Regular;
  font-size: 18px;
  line-height: 33px;
  color: white;
}

.header-title-bg{
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.4);
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-title{
   font-family: 'Playfair Display', serif;
   color: #EAB749;
   font-size: 34px;
   z-index: 10;
}

.back-btn-wrap{
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}


.back-btn-con{
  min-height: 40px;
  width: 300px;
}

.back-btn{
  color: white;
}


@media (min-width: 767px) {
  .back-btn-con{
    width: 600px;
  }
  .pics{

    width: 100vw;
    max-width: 100%;
    justify-content: flex-start;
  }
  .header{
    height: 38.15vw;
    background-size: contain;
    // height: 293px;
    // background-size: 768px 293px;
    background-position: top center;
    background-color: black;
  }
  .desc{
    height: 122.38px;
  }
  .desc-txt{
    width: 482.44px;
  }
  .pic{
    margin-top: 0px;
    max-width: 100vw;
    flex-basis: 100vw;
    height: 240px;
    cursor: pointer;
  }
  .pic-desc{
    width: 100vw;
    height: 240px;
  }
}

@media (min-width: 1365px) {
  .back-btn-con{
    width: 907px;
  }

  .header{

    height: 19.27vw;
    background-size: contain;

    // height: 536px;
    // background-size: 1444px 536px;
  }
  .header-title {
      font-size: 42px;
    }
  .desc-txt {
    width: 866px;
  }
}

@media (min-width: 1465px) {
  .back-btn-con{
    width: 907px;
  }
  .header{

    height: 19.27vw;
    background-size: contain;
    // height: 536px;
    // background-size: 1444px 536px;
  }
  .desc-txt {
    width: 866px;
  }
  .pics{
    // margin-left: calc((100vw - 907px) / 2);
    width: 907px;
  }
  .pic{
    max-width: calc((907px) / 2);
    flex-basis: calc((907px) / 2);
    height: 219px;
  }
  .pic-desc{
    width: calc((907px) / 2);
    height: 219px;
  }
}

</style>

<template>
  <div class="bands">
      <div class="header">
      <div class="header-title-bg">
        <h1 class="header-title">Bands</h1>
      </div>

      <div class="back-btn-wrap">
        <div class="back-btn-con"><router-link to="/" class="back-btn" tag="div">← Back</router-link></div>
      </div>

    </div>
    <div class="desc">
      <div class="desc-txt">{{ word('intro-1') }}</div>
    </div>

    <MenuCompo :tiny="true" />
    <div class="pics-con">

      <div class="pics">
        <div class="pic" v-for="ppl in bands" @touchstart="" @click="goDetail(ppl)">
          <div class="pic-img" :style="`background-image: url(${ppl.imgSrc}); ${ppl.imgBgPos}`"></div>
          <div class="pic-desc">
            <div class="pic-desc-name">{{ ppl.name }}</div>
            <div class="pic-desc-title">{{ ppl.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <P9 />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'

export default {
  name: 'BandsPage',
  components: {
    P9, MenuCompo
  },
  data () {
    return {

    }
  },
  computed: {
    bands () {
      return this.bandsSource.sort((a, b) => {
        return parseFloat(a.order) > parseFloat(b.order)
      })
    },
    ...mapGetters({
      'findWord': 'words/findWord',
      'bandsSource': 'bands/bands'
    }),
    word () {
      return (id) => {
        return this.findWord('band-list-page', id)
      }
    }
  },
  mounted () {
    this.$store.dispatch('words/loadWords', { useRealtime: false })
    this.$store.dispatch('bands/loadBands', { useRealTime: false, mode: 'init' })
  },
  methods: {
    goDetail (band) {
      this.$router.push({
        path: `/bands/${band.name.toLowerCase().split(' ').join('-')}`
      })
    }
  }
}

// bands: [
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   },
//   {
//     name: 'Tony Carpio',
//     title: 'Director',
//     src: 'https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F51900030.699158385-The_Carpio_Music_Premier_8_Piece_Band.png?alt=media&token=a93c9c95-6be1-4ab6-9df0-6091a2f9bd96'
//   }
// ]
</script>
