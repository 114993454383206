const namespace = 'youtubes'
export const ns = namespace
export const pfx = ns + '/'
import { youtubes } from '@/firebase'
import { firebaseAction } from 'vuexfire'
// const codebase = db.ref('codebase')

const state = {
  realtimeYoutubes: [],
  staticYoutubes: [],
  youtubeMode: 'staticYoutubes',
  currentYoutubeIndex: 0
}

const getters = {
  youtubes (state) {
    return state[state.youtubeMode] || []
  },
  currentYoutube (state, getters) {
    var youtubes = getters.youtubes
    if (typeof youtubes[0] !== 'undefined') {
      return youtubes[state.currentYoutubeIndex]
    } else {
      return false
    }
  }
}

const mutations = {
  setCurrentYoutubeIndex (state, index) {
    state.currentYoutubeIndex = index
  },
  clearRealtimeYoutubes (state) {
    state.realtimeYoutubes = []
  },
  useRealtimeYoutubes (state) {
    state.youtubeMode = 'realtimeYoutubes'
  },
  saveStaticYoutubes (state, staticYoutubes) {
    if (staticYoutubes) {
      state.staticYoutubes = Object.keys(staticYoutubes).map((key, index) => {
        return {
          '.key': key,
          ...staticYoutubes[key]
        }
      })
    } else {
      state.staticYoutubes = []
    }
  }
}

const actions = {
  loadYoutubes ({ dispatch }, { useRealtime }) {
    if (useRealtime) {
      dispatch('loadRealtimeYoutubes')
    } else {
      dispatch('loadStaticYoutubes')
    }
  },
  loadStaticYoutubes ({ state, commit }) {
    youtubes.once('value').then((snapshot) => {
      commit('saveStaticYoutubes', snapshot.val())
    })
  },
  loadRealtimeYoutubes: firebaseAction(function ({ state, commit, unbindFirebaseRef, bindFirebaseRef }) {
    commit('useRealtimeYoutubes')
    if (typeof state.realtimeYoutubes[0] !== 'undefined') {
      commit('clearRealtimeYoutubes')
      unbindFirebaseRef('realtimeYoutubes')
    }
    bindFirebaseRef('realtimeYoutubes', youtubes)
  }),
  addYoutube ({ state }, data) {
    return youtubes.push({
      title: 'My New Youtube',
      ...data
    })
  },
  removeYoutube ({ state }, youtube) {
    if (window.confirm('Delete?')) {
      youtubes.child(youtube['.key']).remove()
    }
  },
  saveYoutube ({ state }, youtube) {
    let newObj = { ...youtube }
    delete newObj['.key']
    youtubes.child(youtube['.key']).set(newObj)
  }
}

const moduleObj = {
  mutations,
  ns,
  pfx,
  namespaced: true,
  state,
  actions,
  getters,
  use,
  clean
}

function use (store) {
  store.registerModule(ns, moduleObj)
  // @___@'v
  //

  // store.subscribe((mutation, state) => {
  //   // called after every mutation.
  //   // The mutation comes in the format of `{ type, payload }`.
  // })
}

function clean (store) {
  store.unregisterModule(ns)
}

export default moduleObj
