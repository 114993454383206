const namespace = 'pages'
export const ns = namespace
export const pfx = ns + '/'
import { pages } from '@/firebase'
import { firebaseAction } from 'vuexfire'
// const codebase = db.ref('codebase')

const state = {
  realtimePages: [],
  staticPages: [],
  pageMode: 'staticPages',
  currentPageIndex: 0
}

const getters = {
  pages (state) {
    return state[state.pageMode] || []
  },
  currentPage (state, getters) {
    var pages = getters.pages
    if (typeof pages[0] !== 'undefined') {
      return pages[state.currentPageIndex]
    } else {
      return false
    }
  }
}

const mutations = {
  setCurrentPageIndex (state, index) {
    state.currentPageIndex = index
  },
  clearRealtimePages (state) {
    state.realtimePages = []
  },
  useRealtimePages (state) {
    state.pageMode = 'realtimePages'
  },
  saveStaticPages (state, staticPages) {
    if (staticPages) {
      state.staticPages = Object.keys(staticPages).map((key, index) => {
        return {
          '.key': key,
          ...staticPages[key]
        }
      })
    } else {
      state.staticPages = []
    }
  }
}

const actions = {
  loadPages ({ dispatch }, { useRealtime }) {
    if (useRealtime) {
      dispatch('loadRealtimePages')
    } else {
      dispatch('loadStaticPages')
    }
  },
  loadStaticPages ({ state, commit }) {
    pages.once('value').then((snapshot) => {
      commit('saveStaticPages', snapshot.val())
    })
  },
  loadRealtimePages: firebaseAction(function ({ state, commit, unbindFirebaseRef, bindFirebaseRef }) {
    commit('useRealtimePages')
    if (typeof state.realtimePages[0] !== 'undefined') {
      commit('clearRealtimePages')
      unbindFirebaseRef('realtimePages')
    }
    bindFirebaseRef('realtimePages', pages)
  }),
  addPage ({ state }, payload) {
    pages.push({
      title: 'My New Page',
      path: '/',
      ...payload
    })
  },
  removePage ({ state }, page) {
    if (window.confirm('Delete?')) {
      pages.child(page['.key']).remove()
    }
  },
  savePage ({ state }, page) {
    let newObj = { ...page }
    delete newObj['.key']
    pages.child(page['.key']).set(newObj)
  }
}

const moduleObj = {
  mutations,
  ns,
  pfx,
  namespaced: true,
  state,
  actions,
  getters,
  use,
  clean
}

function use (store) {
  store.registerModule(ns, moduleObj)
  // @___@'v
  //

  // store.subscribe((mutation, state) => {
  //   // called after every mutation.
  //   // The mutation comes in the format of `{ type, payload }`.
  // })
}

function clean (store) {
  store.unregisterModule(ns)
}

export default moduleObj
