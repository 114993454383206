<style lang="scss" scoped>
@import "../../layout.scss";
  .item-detail{
    width: 100%;
    background: #101010;
    color: #ffffff;
  }
  .text-area h2{
    text-align: left;
  }
  .item-detail-wrap{
    padding-top: 40px;
  }

  .item-detail-header{
    width: 100%;
    height: 175px;
    background-size: contain;
    // background-size: 417px 232px;
    background-color: rgba(7, 7, 7, 1.0);
    background-position: top center;
    background-repeat: no-repeat;

    position: relative;



    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .name{
      padding: 0px 10px;
      text-align: center;
      // transform: translateY(15px);
      /* Rosalie Carpio Direc: */
      font-family: PlayfairDisplay-Regular, 'Playfair Display';
      font-size: 36px;
      color: #F6CD45;
      z-index: 10;
    }
    .title{
      // transform: translateY(22px);
      font-family: AvenirNext-Regular, 'Avenir Next';
      font-size: 18px;
      color: white;
      z-index: 10;
    }
  }


  .back-btn-wrap{
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
  }

  .back-btn-con{
    min-height: 35px;
    width: 300px;
  }


  .text-area{
    min-height: calc(100vh - 175px - 103px);
    margin-top: 20px;
    margin-bottom: 20px;
        padding-bottom: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .text {
      margin: 10px;
      width: 325px;
      /* Carpio Music represe: */
      font-family: AvenirNext-Regular, sans-serif;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      img {
        width: 100%;
      }
    }

  }
  .text{
    .iframe{
      height: calc(325px * 0.5625);
    }
  }

  .block{
    width: 100%;
    height: 103px;
    background-color: #F6CD45;
  }

  @media (min-width: 767px) {
    .back-btn-con{
      width: 600px;
    }

  .item-detail-wrap{
    padding-top: 100px;

  }
    .text-area {
      // margin-bottom: 100px;
    }
    .item-detail-header{
      .title,
      .name{
        // transform: translateY(50px);
      }
    }
    .item-detail-header{
      height: 310px;
      background-size: contain;
      // background-size: 775px 424px;
    }
    .block{
      height: 122px;
    }
    .text-area{
      min-height: calc(100vh - 310px - 122px);
    }
    .text-area .text{
      width: 600px;
    }

    .text{
      .iframe{
        height: calc(600px * 0.5625);
      }
    }

  }

  @media (min-width: 1365px) {
    .back-btn-con{
      width: 907px;
    }

    .item-detail-header{
      .title,
      .name{
        // transform: translateY(60px);
      }
    }
    .item-detail-header{
      height: 562px;
      background-size: contain;
      // background-size: 1024px 570px;
    }
    .block{
      height: 131px;
    }
    .text-area{
      min-height: calc(100vh - 562px - 131px);
    }
    .text-area .text{
      width: 907px;
    }
    .text{
      .iframe{
        height: calc(907px * 0.5625);
      }
    }
  }


.banner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 100%);

 .banner-con{
    flex-basis: 1920px;
    max-width: 1920px;
    height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: flex-start;
    // overflow: hidden;

    background-position: center 36%;
    background-size: cover;
    background-repeat: no-repeat;

    filter: brightness(0.6);
    mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%);

    // img {
    //   filter: brightness(0.85);
    //   max-width: 100vw;
    //   max-height: 50vh;
    //   // max-width: 100%;
    //   // max-height: 125%;
    // }

    // @media (min-width: 1439px) {
    //   img {
    //     mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,0,0) 100%);
    //   }
    // }

  }
}

.whitespace{
  white-space: pre-line;
  display: block;
  unicode-bidi: embed;
}


</style>

<template>
  <div class="item-detail">

    <slot name="nav" />
     <!--:style="headerStyle"-->
    <div class="item-detail-header layout-rp">
      <div class="layout-ap layout-tl banner">
        <div class="banner-con layout-rp" :style="`background-image: url(${info.heroImgSrc}); ${info.heroCSS}`">
          <!-- <img :src="info.heroImgSrc" :style="``" /> -->
        </div>
      </div>
      <div class="name">{{ info.name }}</div>
      <div class="title">{{ info.title }}</div>
      <div class="back-btn-wrap">
        <div class="back-btn-con"><slot name="back-btn"></slot></div>
      </div>
    </div>

    <!-- <div class="block"></div> -->
  <div class="item-detail-wrap">
    <div class="text-area">
      <h2 class="text">{{ (subtitle || 'Profile') }}</h2>
      <div class="text whitespace" v-for="para in info.paragraphs">
        {{para.text}}
      </div>
      <div class="text">
        <a :href="info.scanImage">
          <img :src="info.scanImage" alt="">
        </a>
      </div>
      <div class="text">
        <iframe class="iframe layout-fs" v-if="info.video" :src="getEmbedLink(info.video, false)" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="text">
        <iframe class="iframe layout-fs" v-if="info.video2" :src="getEmbedLink(info.video2, false)" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="text">
        <iframe class="iframe layout-fs" v-if="info.video3" :src="getEmbedLink(info.video3, false)" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>

    <slot name="footer" />

  </div>
</div>

</template>

<script>
export default {
  name: 'DetailView',
  props: ['info', 'ratio', 'subtitle'],
  computed: {
    headerStyle () {
      let ratio = {}

      if (this.info.heroImgSrc) {
        ratio.hegiht = 310 / 961 * 100 + 'vw'
        ratio.backgroundSize = 'contain'
        ratio.backgroundImage = `url(${this.info.heroImgSrc})`
      }

      return {
        ...ratio
      }
    }
  },
  methods: {
    getEmbedLink (url, autoplay) {
      let vid = ''

      if (url.indexOf('https://youtu.be/') !== -1) {
        url = url.replace('https://youtu.be/', '')
        if (url.indexOf('?t=') !== -1) {
          let arr = url.split('?t=')
          vid = arr[0]
        } else {
          vid = url
        }
      } else if (url.indexOf('https://www.youtube.com/watch?v=') !== -1) {
        vid = url.replace('https://www.youtube.com/watch?v=', '')
      }
      if (!vid) {
        return false
      } else {
        return `https://www.youtube.com/embed/${vid}?rel=0${autoplay ? '&autoplay=1' : ''}`
      }
    }
  }
}
</script>

