const namespace = 'contacts'
export const ns = namespace
export const pfx = ns + '/'
import { contacts } from '@/firebase'
import { firebaseAction } from 'vuexfire'
// const codebase = db.ref('codebase')

const state = {
  realtimeContacts: [],
  staticContacts: [],
  contactMode: 'staticContacts',
  currentContactIndex: 0,
  loadCursor: 0
}

const getters = {
  canLoadNewer (state) {
    return state.loadCursor > 0
  },
  canLoadOlder (state, getters) {
    return getters.contacts.length > 1
  },
  reverseContacts (state, getters) {
    return getters.contacts.slice().reverse() || []
  },
  contacts (state) {
    return state[state.contactMode] || []
  },
  currentContact (state, getters) {
    var contacts = getters.contacts
    if (typeof contacts[0] !== 'undefined') {
      return contacts[state.currentContactIndex]
    } else {
      return false
    }
  }
}

const mutations = {
  setCurrentContactIndex (state, index) {
    state.currentContactIndex = index
  },
  clearRealtimeContacts (state) {
    state.realtimeContacts = []
  },
  useRealtimeContacts (state) {
    state.contactMode = 'realtimeContacts'
  },
  saveStaticContacts (state, staticContacts) {
    if (staticContacts) {
      state.staticContacts = Object.keys(staticContacts).map((key, index) => {
        return {
          '.key': key,
          ...staticContacts[key]
        }
      })
    } else {
      state.staticContacts = []
    }
  }
}

function getQuery ({ query, arr, state, mode, num }) {
  // var query = contacts
  if (mode === 'init') {
    state.loadCursor = 0
    query = query.orderByKey().limitToLast(num)
  } else if (mode === 'older') {
    let target = arr[arr.length - 1]
    if (!target) {
      throw new Error('needs target')
    }
    let cursor = target['.key']
    if (!cursor) {
      throw new Error('needs key')
    }
    query = query.orderByKey().endAt(cursor).limitToLast(num)
    state.loadCursor++
  } else if (mode === 'newer') {
    let target = arr[0]
    if (!target) {
      throw new Error('needs target')
    }
    let cursor = target['.key']
    if (!cursor) {
      throw new Error('needs key')
    }
    query = query.orderByKey().startAt(cursor).limitToFirst(num)
    state.loadCursor--
  }
  return query
}

const actions = {
  loadContacts ({ dispatch }, { useRealtime, mode, num }) {
    if (useRealtime) {
      dispatch('loadRealtimeContacts', { mode, num })
    } else {
      dispatch('loadStaticContacts', { mode, num })
    }
  },
  loadStaticContacts ({ state, commit, getters }, { mode, num }) {
    var query = getQuery({ query: contacts, arr: getters.reverseContacts, state, mode, num })
    query.once('value').then((snapshot) => {
      commit('saveStaticContacts', snapshot.val())
    })
  },
  loadRealtimeContacts: firebaseAction(function ({ state, commit, unbindFirebaseRef, bindFirebaseRef, getters }, { mode, num }) {
    var query = getQuery({ query: contacts, arr: getters.reverseContacts, state, mode, num })
    commit('useRealtimeContacts')
    if (typeof state.realtimeContacts[0] !== 'undefined') {
      commit('clearRealtimeContacts')
      unbindFirebaseRef('realtimeContacts')
    }
    bindFirebaseRef('realtimeContacts', query)
  }),
  addContact ({ state }, data) {
    return contacts.push({
      name: 'My New Contact',
      ...data
    })
  },
  removeContact ({ state, dispatch }, contact) {
    if (window.confirm('Delete?')) {
      contacts.child(contact['.key']).remove()
    }
  },
  saveContact ({ state }, contact) {
    let newObj = { ...contact }
    delete newObj['.key']
    contacts.child(contact['.key']).set(newObj)
  }
}

const moduleObj = {
  mutations,
  ns,
  pfx,
  namespaced: true,
  state,
  actions,
  getters,
  use,
  clean
}

function use (store) {
  store.registerModule(ns, moduleObj)
  // @___@'v
  //

  // store.subscribe((mutation, state) => {
  //   // called after every mutation.
  //   // The mutation comes in the format of `{ type, payload }`.
  // })
}

function clean (store) {
  store.unregisterModule(ns)
}

export default moduleObj
