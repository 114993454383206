 <style lang="scss" scoped>

p {
    color: #ffffff;
    line-height: 28px;
}
h1 {
  font-weight: 100;
}
h2 {
    font-family: 'Avenir', sans-serif;
    line-height: 28px;

}

.padding {
    padding: 40px 0;
}

.divider {
    border-bottom: 1px solid #523d00;
    width: 300px;
    margin: 0 auto;
}

.sub-head {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
}
.header{
  width: 100%;
  height: 41.06vw;
  // max-height: 50vh;
  background-size: cover;
  // height: 155px;
  // background-size: 415px 155px;
  background-position: top center;
  background-color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F13398180.58929092-18121042_1249586768487448_2712921532293427601_o-3.jpg?alt=media&token=35142f09-c27e-4494-ab86-58c0e617a142");
  // background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title-bg{
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.4);
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-title{
   font-family: 'Playfair Display', serif;
   color: #EAB749;
   font-size: 28px;
   z-index: 10;
}

.flex-container {
    display: flex;
    flex-direction: column;
    max-width: 1190px;
    // background-color:#EAB749;
    margin: 0 auto;
    align-items: center;
    padding: 30px 0;
}
.container {
    padding:8%;
    max-width: 1200px;
    margin: 0 auto;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.box-1 {
//     display: grid;
//     justify-content: center;
//     align-content: center;
//     grid-template-columns: 1fr 1fr;
//     grid-template-rows: 160px 160px;
//     flex-basis: 50%;
//
    max-width: 600px;


}

.box-1 .item1 {
    background-color: red;
    grid-column: 1/3;
    grid-row: 1/3;
}



.box-1 img{
    width: 100%;

}



.box-2 {
    flex-basis: 50%;
    padding:0 8% 0 8%;
    // margin-top: -50px;
}

.box-3 {
    flex-basis: 50%;
    padding:0 8% 0 8%;
    order: 2;
    // margin-top: -50px;
}
.box-4 img{
    width: 100%;
}

.box-4 {
    order: 1;
    max-width: 600px;
    // margin-top: -50px;
}


.box-2 h2,
.box-3 h2{
     font-size: 32px;
    line-height: 34px;
    z-index: 3;
     color: #D7a53A;
     text-align: center;
}

.live-entertainment-body {
    background: #101010;
    background-image: url("");

}




@media (min-width: 767px) {
    .box-1 {

    }

  .back-btn-con{
    width: 600px;
  }

}

@media (min-width: 1365px) {
    .header-title {
        font-size:42px;
    }
    .flex-container {
    flex-direction: row;}
      .box-2 h2{
     text-align: left;
    }
    .box-3 {
         order: 1; // Reverse order for responsive
    }
     .box-4 {
        order: 2; // Reverse order for responsive
    }


    .box-2 {
        padding:0 0 0 8%;
        // margin-top: -50px;
    }
}

@media (min-width: 1439px) {



    .box-2 h2,
    .box-3 h2{
     text-align: left;
    }

    .box-3 {
        padding-left:0;
    }

}









 </style>

 <template>

<div class="live-entertainment-body">
    <div class="header">
      <div class="header-title-bg">
        <h1 class="header-title">Live Entertainment</h1>
      </div>
    </div>

    <div class="container">
        <h2 class="sub-head">Carpio Music has been providing rich live entertainment for a variety of personal, business and prestegious events for Over 40 years in Hong Kong. </h2>
        <p>Wherever the venue, whichever the style – We bring events to life through good performance and music. Complimented with our State-of-the-art audio equipment,
            We love giving your events the highest quality live audio so your audiences and attendees can finally enjoy themselves.</p>
    </div>

    <div class="flex-container">
        <div class="box-1">
            <!-- <div class="item1"></div> -->
            <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F1643474.9198857457-carpio-music-corporate1.jpg?alt=media&token=fe412e06-c2a7-4561-9259-60f7cdd0b4e4">
        </div>
        <div class="box-2">
            <h2>Corporate Events</h2>
            <p>Hong Kong's most comprehensive corporate entertainment choice.</p>


            <p>Unlike a recording, Live Music can dictate the mood of an audience, depending on the focus of the event, whether it
                be a Launch Party, Fundraising Dinner, Listing Dinner or Company Anniversary. Impress your clients by letting us create
                the perfect atmosphere. We adjust our repertoire depending on needs covering Asian and Western favorites.</p>
 
            <p>Whether you need a background band or a show, we can add excitement and anticipation for your company's event.</p>
        </div>
    </div>

<div class="padding divider"></div>
<div class="padding"></div>

    <div class="flex-container">
        <div class="box-3">
            <h2>Weddings</h2>
            <p>For over 35 years we have been providing great entertainment to Weddings held at all venues. Our band's sought-after
                blend of a variety of music and live-entertainment caters for every type of bride. We also provide adaptable formats such as jazz, Funk,
                disco to suit the needs of every wedding.</p>

        </div>


        <div class="box-4">
            <!-- <div class="item1"></div> -->
            <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F50768607.12331168-Weddings-01-carpio-brothers.jpg?alt=media&token=4b68c2af-93ae-4714-bf93-6e1477893782">
        </div>

    </div>
<div class="padding divider"></div>
<div class="padding"></div>
 <div class="flex-container">
        <div class="box-1">
            <!-- <div class="item1"></div> -->
            <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F96358001.45462415-carpio-music-private-events.png?alt=media&token=51ca5ea8-96c8-4047-91aa-8c87d8ac8b9f">
        </div>
        <div class="box-2">
            <h2>Private Events</h2>
            <p>Birthdays, Anniversaries, Annual Dinners, New Years' Eve Celebrations and the like. You name them and we've done them,
                from a Star Wars theme Annual Dinner, Great Gatsby style Birthday to Romantic French setting Anniversaries. </p>

                <p>Every musical
                detail will be there to set the vibe and ensure your party is a big success. Whether you're looking for a relaxed atmosphere
                or a high energy dance fest, your happiness is our number one priority.</p>



        </div>
    </div>
<div class="padding divider"></div>
<div class="padding"></div>

 <div class="flex-container">
        <div class="box-3">
            <h2>In-House Entertainment</h2>
            <p>Carpio Music does not simply bring musicians to your venue, our expertise is finding well rounded artists and
                continuously growing their abilities to perform for your audience. </p>

<p>With the ever changing pop culture, our bands play a wide range of classics and modern hits. To guarantee customer satisfaction, we program our artists music to ensure the entertainment matches the venues environment (Hotels, Bars, Restaurants, Clubs)
</p>

        </div>


        <div class="box-4">
            <!-- <div class="item1"></div> -->
            <img src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F59794701.97863526-carpio-music-inhouse-events.png?alt=media&token=e037b2b9-2ece-4ec3-81b4-8ff36f5bf277">
        </div>

    </div>

<div class="padding"></div>



    <MenuCompo :tiny="true" />



    <P9 />


</div>



 </template>

 <script>
import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'

export default {
  components: { P9, MenuCompo }
}
 </script>

