<style lang="scss" scoped>
@import "../../layout.scss";

.hero {
  background-color: black;
}

.vid-con {
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center center;

  .hero-video-el {
    max-height: 100vmax;
    max-width: auto;
  }
}

.black-bg {
  background-color: black;
}

.white-bg {
  background-color: white;
}

.hero {
  display: block;
}
// @media (max-width: 500px) {
//   .hero{
//     display: none;
//   }
// }
</style>

<template>
  <div class="carpio-home">
    <P12 />
    <!-- <div class="hero layout-fv layout-rp">

      <div ref="heroSection" class="vid-con layout-fs layout-ap layout-tl" v-if="word('hero-cover-img@1x')" :style="`background-image: url(${respHeroCover});`">
         <video ref="heroVid" autoplay loop class="hero-video-el scrollFade"  v-if="!isSmallScreen">
          <source :src="`${word('hero-video-link')}`" type="video/mp4" />
        </video>
      </div>

    </div> -->

    <MenuCompo :open="isMenuOpen" @toggle="isMenuOpen = !isMenuOpen" />

    <!--<pre>{{ word('hero-cover-img') }}</pre>-->
    <div class="black-bg">
      <P11 />

      <div class="white-bg">
        <P4 />
        <P5 />
        <!-- <P6 /> -->
        <!-- <P3 /> -->
        <!-- <VideoGrid /> -->
        <VideoCarousel />
      </div>
      <P8 />
      <P9 />
    </div>

    <!--tall div-->
    <!--<div style="height: 100vh; background-color: rgba(0,0,0,0.8);"></div>-->
  </div>
  <!--wrapper-->
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MenuCompo from "./Menu";
import P2 from "./P2_1";
import P3 from "./P3";
import P4 from "./P4";
import P5 from "./P5";
import P6 from "./P6";
import P8 from "./P8";
import P9 from "./P9";
import P11 from "./P11";
import P12 from "./P12";

import VideoCarousel from "./VideoCarousel";

export default {
  components: {
    MenuCompo,
    P2,
    P3,
    P4,
    P5,
    P6,
    P8,
    P9,
    P11,
    P12,
    VideoCarousel,
  },
  name: "Home",
  data() {
    return {
      clean: () => {},
      isAtTop: true,
      iOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      scolledOverHero: false,
      isSmallScreen: false,
      isMenuOpen: false,
    };
  },
  watch: {
    isMenuOpen() {
      if (this.$refs.heroVid) {
        if (this.isMenuOpen) {
          this.$refs.heroVid.pause();
        } else {
          this.$refs.heroVid.play();
        }
      }
    },
    scolledOverHero() {
      if (this.$refs.heroVid) {
        if (this.scolledOverHero) {
          this.$refs.heroVid.pause();
        } else {
          this.$refs.heroVid.play();
        }
      }
    },
  },
  computed: {
    respHeroCover() {
      return window.innerWidth < 500
        ? this.word("hero-cover-img@1x")
        : this.word("hero-cover-img@2x");
    },
    ...mapState({
      pages: (state) => state.pages[state.pages.pageMode],
      words: (state) => state.words[state.words.wordMode],
    }),
    ...mapGetters({
      findWord: "words/findWord",
    }),
    word() {
      return (id) => {
        return this.findWord("home-page", id);
      };
    },
  },
  mounted() {
    this.$store.dispatch("words/loadWords", {
      useRealtime: !!this.$store.state.user,
    });

    var onResize = () => {
      if (window.innerWidth <= 500) {
        this.isSmallScreen = true;
      }
    };
    onResize();
    var onScroll = () => {
      this.isAtTop = window.scrollY === 0;
      this.scolledOverHero = window.scrollY > window.innerHeight * 0.65;
      if (this.$refs["heroSection"]) {
        this.$refs["heroSection"].style.opacity = Math.abs(
          (window.innerHeight - window.scrollY) / window.innerHeight
        );
      }
    };
    onScroll();

    window.addEventListener("resize", onResize, false);
    window.addEventListener("scroll", onScroll, false);
    this.clean = () => {
      window.removeEventListener("scroll", onScroll, false);
      window.removeEventListener("resize", onResize);
    };
  },
  beforeDestroy() {
    this.clean();
  },
};
</script>
