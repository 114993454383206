import Vue from "vue";
import VueFire from "vuefire";
import firebase from "firebase";
export default firebase;

Vue.use(VueFire);

export const fire = firebase.initializeApp({
  apiKey: "AIzaSyAt7dgFin0VYF_FZ08yt8FWvS6Qk_99TtY",
  authDomain: "carpio-music.firebaseapp.com",
  databaseURL: "https://carpio-music.firebaseio.com",
  projectId: "carpio-music",
  storageBucket: "carpio-music.appspot.com",
  messagingSenderId: "648978104986",
});

export const db = fire.database();

export const storage = firebase.storage();

export const publicImages = db.ref("/images/public");
export const galleries = db.ref("/galleries");
export const youtubes = db.ref("/youtubes");
export const events = db.ref("/events");
export const pages = db.ref("/pages");
export const words = db.ref("/words");
export const contacts = db.ref("/contacts");
export const artists = db.ref("/artists");
export const bands = db.ref("/bands");
export const clients = db.ref("/clients");
