const namespace = 'bands'
export const ns = namespace
export const pfx = ns + '/'
import { bands } from '@/firebase'
import { firebaseAction } from 'vuexfire'
// const codebase = db.ref('codebase')

const state = {
  realtimeBands: [],
  staticBands: [],
  bandMode: 'staticBands',
  currentBandIndex: 0,
  loadCursor: 0
}

const getters = {
  canLoadNewer (state) {
    return state.loadCursor > 0
  },
  canLoadOlder (state, getters) {
    return getters.bands.length > 1
  },
  reverseBands (state, getters) {
    return getters.bands.slice().reverse() || []
  },
  bands (state) {
    return state[state.bandMode] || []
  },
  currentBand (state, getters) {
    var bands = getters.bands
    if (typeof bands[0] !== 'undefined') {
      return bands[state.currentBandIndex]
    } else {
      return false
    }
  }
}

const mutations = {
  setCurrentBandIndex (state, index) {
    state.currentBandIndex = index
  },
  clearRealtimeBands (state) {
    state.realtimeBands = []
  },
  useRealtimeBands (state) {
    state.bandMode = 'realtimeBands'
  },
  saveStaticBands (state, staticBands) {
    if (staticBands) {
      state.staticBands = Object.keys(staticBands).map((key, index) => {
        return {
          '.key': key,
          ...staticBands[key]
        }
      })
    } else {
      state.staticBands = []
    }
  }
}

function getQuery ({ query, arr, state, mode, num }) {
  // var query = bands
  if (mode === 'init') {
    state.loadCursor = 0
    if (num) {
      query = query.orderByKey().limitToLast(num)
    }
  } else if (mode === 'older') {
    let target = arr[arr.length - 1]
    if (!target) {
      throw new Error('needs target')
    }
    let cursor = target['.key']
    if (!cursor) {
      throw new Error('needs key')
    }
    query = query.orderByKey().endAt(cursor).limitToLast(num)
    state.loadCursor++
  } else if (mode === 'newer') {
    let target = arr[0]
    if (!target) {
      throw new Error('needs target')
    }
    let cursor = target['.key']
    if (!cursor) {
      throw new Error('needs key')
    }
    query = query.orderByKey().startAt(cursor).limitToFirst(num)
    state.loadCursor--
  }
  return query
}

const actions = {
  loadBands ({ dispatch }, { useRealtime, mode, num }) {
    if (useRealtime) {
      dispatch('loadRealtimeBands', { mode, num })
    } else {
      dispatch('loadStaticBands', { mode, num })
    }
  },
  loadStaticBands ({ state, commit, getters }, { mode, num }) {
    var query = getQuery({ query: bands, arr: getters.reverseBands, state, mode, num })
    query.once('value').then((snapshot) => {
      commit('saveStaticBands', snapshot.val())
    })
  },
  loadRealtimeBands: firebaseAction(function ({ state, commit, unbindFirebaseRef, bindFirebaseRef, getters }, { mode, num }) {
    var query = getQuery({ query: bands, arr: getters.reverseBands, state, mode, num })
    commit('useRealtimeBands')
    if (typeof state.realtimeBands[0] !== 'undefined') {
      commit('clearRealtimeBands')
      unbindFirebaseRef('realtimeBands')
    }
    bindFirebaseRef('realtimeBands', query)
  }),
  addBand ({ state }, data) {
    return bands.push({
      name: 'My New Band',
      ...data
    })
  },
  removeBand ({ state, dispatch }, band) {
    if (window.confirm('Delete?')) {
      bands.child(band['.key']).remove()
    }
  },
  saveBand ({ state }, band) {
    let newObj = { ...band }
    delete newObj['.key']
    bands.child(band['.key']).set(newObj)
  }
}

const moduleObj = {
  mutations,
  ns,
  pfx,
  namespaced: true,
  state,
  actions,
  getters,
  use,
  clean
}

function use (store) {
  store.registerModule(ns, moduleObj)
  // @___@'v
  //

  // store.subscribe((mutation, state) => {
  //   // called after every mutation.
  //   // The mutation comes in the format of `{ type, payload }`.
  // })
}

function clean (store) {
  store.unregisterModule(ns)
}

export default moduleObj
