<style lang="scss" scoped>
h2{
  font-family: 'Playfair Display',serif;
  font-size:30px;
  font-weight:100;
}
.strong {
  font-weight: 700;
}

ol {
  margin:0 0 1.5em;
  padding:0;
  counter-reset:item;
  // font-size: 16px;
}
.resp {
  width: 100%;
}

ol>li {
  margin:0;
  padding:0 0 1em 2em;
  text-indent:-2em;
  list-style-type:none;
  counter-increment:item;
}
 
ol>li:before {
  display:inline-block;
  width:1.5em;
  padding-right:0.5em;
  font-weight:bold;
  text-align:right;
  content:counter(item) ".";
}

ul {
  columns: 1;
}
ul li{
  line-height: 40px;
}

.header{
  width: 100%;
  height: 41.06vw;
  // max-height: 50vh;
  background-size: cover;
  // height: 155px;
  // background-size: 415px 155px;
  background-position: top center;
  background-color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F54743562.78231545-Cover-education.jpg?alt=media&token=0b28389b-b1c4-401a-8d45-33bc53034e13");
  // background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title-bg{
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.4);
   z-index: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-title{
   font-family: 'Playfair Display', serif;
   color: #EAB749;
   font-size: 28px;
   z-index: 10;
   font-weight: 100;
}

.padding-first {
  padding-top: 25px;
}

.lg-pic-container {
  text-align: center;
}
.lg-pic-container img{
  width:100%;
  max-width:840px;
}

.desc{
  width: 100%;
  height: 161px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6CD45;
}
.desc-txt{
  width: 258.5px;
}

.pics{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.pic{
  max-width: 50vw;
  flex-basis: 50vw;
  height: 50vw;
  position: relative;
}
.pic-img{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.pic-desc{
  opacity: 0;
  transition: opacity 1s ease;
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50vw;
  height: 50vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pic:hover,
.pic:active {
  .pic-desc{
    opacity: 1;
  }
}

.pic-desc-name{
  /* Tony Carpio Director: */
  font-family: PlayfairDisplay-Regular;
  font-size: 25px;
  line-height: 33px;
  color: #F6CD45;
}
.pic-desc-title{
  font-family: AvenirNext-Regular;
  font-size: 12px;
  line-height: 33px;
  color: white;
}
.picture-container{
    display:flex;
    align-items: center;
    flex-direction: column;
  }

.picture-container-full{
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;

  }

.picture-container-full img{
    width:100%;
       
  }

.picture-container img {
    width: 330px;
    // height: 100%;
    padding-top:10px;
  }
.education-content{
    padding: 8%;
  }
.education-content-2{
      padding: 8%;
    display: flex;
    justify-content: center;
}
.list-container {
    width: 960px;
}
.container{
    max-width: 1200px;
  }
.education-content .container p{
    line-height:24px;
  }

.full-width-container{
  width: 100vw;
}
.full-width-container img{
  width: 100%;
}
.container-flex-start{
  justify-content: flex-start;
  padding: 8%;
}
  @media (min-width: 767px) {
    .header{

      height: 41.27vw;
      background-size: cover;

      // height: 293px;
      // background-size: 768px 293px;
      background-position: top center;
      background-color: black;
    }

    .header-title {
      font-size: 42px;
    }
    .desc{
      height: 122.38px;
    }
    .desc-txt{
      width: 760.44px;
    }
    .pic{
      max-width: 25vw;
      flex-basis: 25vw;
      height: 25vw;
    }
    .pic-desc{
      width: 25vw;
      height: 25vw;
    }
    .picture-container{
      display:flex;
      justify-content: space-between;
      flex-direction: row;
    }
    .picture-container img {
      width: 310px;
    }
    .education-content{
      display:flex;
      justify-content: center;
      padding: 0% 8% 0% 8%;
    }
    .container{
      max-width: 1200px;
      padding:3%;
    }
    .education-content .container p{
      line-height:24px;
    }
    ul {
      columns: 2;
      list-style-position: inside;
    }
    .education-content-2{
        padding: 2% 8% 2% 8%;
    }
}

@media (min-width: 1365px) {
  .header{

    height: 41.27vw;
    background-size: cover;

    // height: 536px;
    // background-size: 1444px 536px;
  }

   .header-title {
    font-size: 42px;
  }
  .desc-txt {
    width: 866px;
  }
  .picture-container{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .picture-container img {
    width: 350px;
    // height: 100%;
  }
  ul {
    columns: 2;
  }
}

@media (min-width: 1439px) {
  .header{

    height: 41.27vw;
    background-size: cover;

    // height: 536px;
    // background-size: 1444px 536px;
  }

   .header-title {
    font-size: 42px;
  }
  .desc-txt {
    width: 866px;
  }
  .pics{
    margin-left: calc((100vw - 907px) / 2);
    width: 907px;
  }
  .pic{
    max-width: calc((907px) / 4);
    flex-basis: calc((907px) / 4);
    height: calc((907px) / 4);
  }
  .pic-desc{
    width: calc((907px) / 4);
    height: calc((907px) / 4);
  }
  .education-content{
    padding:0%;
  }
  
  .education-content .container p{
    line-height:24px;
  }
  .picture-container{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .picture-container img {
    width: 390px;
    // height: 100%;
  }
  ul {
    columns: 2;
  }
}


@media (min-width: 767px) and (max-width: 1100px) {
  .picture-container img {
      width: 33.33%;
  }
}


</style>



<template>
   <div class="artists">
    <div class="header">
      <div class="header-title-bg">
        <h1 class="header-title">Education</h1>
      </div>
    </div>

    <div class="education-content">
      <div class="container">
        <h2>Why Us?</h2>
        <h3 class="padding-first">Approach</h3>
        <p>
        We believe music is an art form that needs to be nurtured, focusing on the practical elements of music. Applied theory is a major
        component of our classes, we structure our lessons to give students the opportunity to experiment with ideas and guide them to improve
        their understanding. This hands on approach allows us to focus on the enjoyments of music - playing and performance.
        </p>
        <p>With our performance focus,  we ensure to inspire our student’s musical dreams and support their educational journey within music.</p>

        <p>Our true love is Jazz and we also teach a wide variety of different styles: Pop, Rock, Classical, Funk, etc.</p>
        <br>
        <h3>Benefits</h3>
        <p>With our lessons having a heavy performance focus, we only hire tutors with vast experience as professionals themselves.
          Our tutors share their experiences as an industry professional and help streamline learning by guiding students on what they
          need to practice, perform and work on as a musician.
        </p>

        <p>Our mission is to provide students a platform to perform through a vast number of avenues. Annually we work in conjunction with
          YRock’s music competition, which in turn gives students further performance opportunities at events like Picnic in the park, Clockenflap,
          Hong Kong Rugby Sevens and more. We also provide further opporturnities at our company’s private events and jam sessions.
          </p>
          <br>
        <h3>Network</h3>
        <p>Our connections with partners based in the United States, Singapore, Australia and more, we are always looking to pursue new opportunities.
        </p>
        <p>With our extensive Network in Hong Kong ranging from restaurants, hotels, music companies and organizations, we have the ability to continue
          our education outside of the classroom as well as connect students with potential opportunities locally and overseas.
        </p>

        <!-- _HERE_-->
        <div class="picture-container">
          <img alt="Carpio Music Drum education boy" src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F44373602.14932371-carpio-music-education-01.jpg?alt=media&token=206e4869-e801-4b70-a68a-2535dc874dea" />
          <img alt="Chris Carpio teaching kids at KGV school" src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F35931064.25904584-carpio-music-education-kgv-02.jpg?alt=media&token=6199075f-f1e3-4f6a-b21a-dcccaedae301" />
          <img alt="Bernard Carpio teaching flute in classroom" src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F1275533.2232680328-carpio-music-education-bernard-flute-03.jpg?alt=media&token=9bcbb489-a7b4-4f05-b177-643aa6d11e95" />
        </div>

        <!-- _END-HERE_-->
        <br>
        <br>

        <h2>Tuition</h2>
        <p>At Carpio Music, we cater to students of all levels and ages. If students are looking for a more comprehensive learning experience, private
          lessons would be more suitable.  Group classes are a great way for students looking to learn with friends or are a bit shy to learn alone.
        </p>
        <br>
        <h3>Private Lessons (1v1) adults/children</h3>
        <p>With our one on one classes we tailor-make our lessons based on the students learning speed and interests, keeping the lessons fun and captivating.
        </p>
        <p>We mix theory and technical training in between learning songs and encourage improvisation to enhance our students creativity.
        </p>
        <br>
        <h3>Group Lessons</h3>
        <p>Learning in groups can be great to get started on a new instrument or to play with other people. Unlike one on one lessons, group learning can be beneficial to
          encourage students to be more social musically and teach them techniques to lead and run groups effectively.
        </p>
        <br>
        <h3>Workshops</h3>
        <p>Focusing on a number of Major topics like “Basic Improvisation”, “Jamming In A Band” and “Advanced Harmony”, our Workshops heavily focus on the
          implementation of concepts which are intensive and stimulating. Blocks of theory and lectures followed by playing, pushes students to experiment
          with ideas and allows them to fully absorb the information effectively.
        </p>
        <p>Workshops can be tailor-made for different groups and can focus on different topics.
        </p>
        <br>
        <h3>Music Theory</h3>
        <p>Scales, chords and rhythm are the fundamentals of music and our approach is to merge these rudiments within the students interests. Whilst learning
          songs, we mix in between the theory and technical training to provide students with essential concepts as well as to drive their enjoyment.
        </p>
        <p>Whether the student is keen on improvisation or traditional learning, we adapt their education within our curriculum.
        </p>
        <br>
        </div>
    </div>
    <div class="lg-pic-container">
      <img alt="Rosalie Carpio teaching Vocals in classroom" src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F17364885.635098837-carpio-music-education-kgv-03.jpg?alt=media&token=e0c5825c-885f-43cf-8e2c-db73a74be01c">
    </div>

      <div class="education-content-2">
      <div class="list-container">
        <h2>Instruments</h2>

        <ul>
          <li>Jazz & Pop Piano</li>
          <li>Classical Piano</li>
          <li>Jazz & Pop Flute</li>
          <li>Classical Flute</li>
          <li>Jazz & Pop Saxophone</li>
          <li>Rock, Pop & Jazz Drums</li>
          <li>Rock, Pop & Jazz Guitar</li>
          <li>Classical Guitar</li>
          <li>Rock, Pop & Jazz & Bass</li>
          <li>Jazz & Pop Acoustic Bass</li>
          <li>Classical Acoustic Bass</li>
          <li>Jazz & Pop Vocal</li>
          <li>Rock & Pop Ukelele</li>
        </ul>




      </div>
      </div>

      <div class="picture-container-full">
      <img class="resp" src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F32749091.097654648-carpio-music-education-instruments.jpg?alt=media&token=8f5ce797-edc9-4ec4-b6ae-27ec08d48c3a">
      </div>



      <div class="education-content">
      <div class="container">
        <h3>Terms and Conditions</h3>
        <ol>

      <li>If a student does not show up for lesson without prior notifications, no makeup lesson will be arranged.</li>
      <li>Tuitions should be paid in advance; otherwise their selected time slot may be allocated to other students. 
      Cheques are to be made out to “Carpio Music”.</li>
      <li>Only one casual leave is allowed every four lessons within 24-hours notifications, otherwise no 
      makeup lessons or refunds will be arranged.</li>
      <li>Carpio Music may publish the photographs and videos taken of the student and/or the undersigned minor children, 
      and their names, for use in Carpio Music’s printed publications, website and training purposes. Please notify via writing if the student does not 
      wish their photographs to be used in Carpio Music’s publications.</li>
      <li>We reserve the right to change or add to these terms and conditions from time to time for legal, safety or other 
      substantive reasons or in order to assist the proper delivery of education at the School. Carpio Music will send you notice of any such modifications 
      prior to the end of the penultimate term before the modifications are to take effect.</li>
        </ol>
      </div>
      </div>
      





    <MenuCompo :tiny="true" />



    <P9 />

  </div>



</template>

<script>

import P9 from '@/components/Home/P9'
import MenuCompo from '@/components/Home/Menu'

export default {
  components: { P9, MenuCompo }
}
</script>

<style lang="scss" scoped>
  .education {
      background-color:red;
      width: 30%;
      height: 30%;

  }
</style>
