<style lang="scss" scoped>
@import "../../layout.scss";


p {
    // font-family: 'Nunito', sans-serif;
    color: #000000;
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;
}

h1 {
    font-family: 'Playfair Display', serif;
    font-size: 34px;
    color: #000;
    margin-top: 10vh;
    font-weight: 100;
}


.flex-container {
display: flex;
    flex-direction: column;
    margin-left:10vw;
    margin-right:10vw;
    // padding-bottom: 50px;
}

.strong {
  font-weight:700;
}

.text-ctr {
  text-align: center;
  color: #929292;
}


.learn-more{
  cursor: pointer;
  width: 173px;
  height: 52px;
  /* Rectangle 4: */
  background: #000000;
  font-family: Raleway-Bold, 'Raleway', sans-serif;
  font-size: 11px;
  color: #FFFFFF;
  letter-spacing: 1.56px;
  // line-height: 21px;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  text-align: center;
  line-height: 52px;
  transition-property: background-color;
}

.learn-more:hover {
  background-color: #D7a53A;
}

.learn-more-box{
  width: 100%;
  height: 110px;
  // margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.resp-con{
  width: 288px;
}

.artists{
  width: 100vw;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;

  background-color: white;
  background-repeat: repeat repeat;
}

.artists-con {
  max-width: 1204px;
  margin: 0 auto;
}
.pre-padding{
  height: 0px;
}

@media (orientation: landscape) and (max-height: 500px) {
  .artists{
    min-height: 100vmax;
  }
}

.keykey-con{
  width: 100%;
  height: 100vh;
}

.keykey{
  opacity: 0.5;
  width: 100%;
  height: 100vh;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F85795327.05462356-iPhone_7_Plus.png?alt=media&token=2a47a3cd-67f9-4157-b143-c93c3da7e6ad");
  background-position: center center;
  background-repeat: no-repeat no-repeat;
}

.title {
  /* Artists: */
  font-family: PlayfairDisplay-Regular, 'Playfair Display', serif;
  font-size: 36px;
  color: #000000;
  line-height: 44px;
}

.title-bar{
  width: 100%;
  display: flex;
  justify-content: center;
}

.title-box{
  margin-top: 108px;
  height: 80px;
}

.yellow-line{
  width: 107px;
  height: 3px;
  background-color: #F6CD45;
  margin-top: 9px;
  margin-left: -2px;
}
.artists-list{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80vw;
    max-width: 1204px;
}
.artists-item{
  cursor: pointer;

  width: 100%;
  height: 156px;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: relative;

  overflow: hidden;

  .artists-name{
    z-index: 5;
    font-family: PlayfairDisplay-Regular, 'Playfair Display', serif;
    font-size: 30px;
    line-height: 40px;
    color: #F6CD45;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .artists-title {
    z-index: 5;
    font-size: 18px;
    color: white;
    line-height: 40px;
    margin: -6px 0px;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .artists-dimmer{
    position: absolute;
    z-index: 3;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .artists-bg{
    position: absolute;
    z-index: 3;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    background-size: cover;
    background-position: center center;
    transition: transform 0.5s ease;

    transform: scale(1.0);
  }
}

.is-touched-once{
  .artists-name,
  .artists-title{
    opacity: 1;
  }
  .artists-dimmer{
    opacity: 1;
  }
}

.artists-item:hover {
  .artists-bg{
    transform: scale(1.1);
  }
  .artists-name,
  .artists-title{
    opacity: 1;
  }
  .artists-dimmer{
    opacity: 1;
  }
}


@media (min-width: 767px) {
  .resp-con{
    width: 600px;
  }
  .keykey{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F18681724.03599646-iPad.png?alt=media&token=242b6bea-84ec-43db-bbce-cd9d48bc8263");
  }
  .title-box{
    margin-top: 116px;
    // height: 70px;
  }

  // .artists-item{
  //   height: 158px;
  //   background-position: center 18%;
  // }

  .artists-list{
    flex-wrap: wrap;
  }
  .artists-item{
    flex-basis: 50%;
    height: 329px;
  }
}

@media (min-width: 1365px) {
  .resp-con{
    width: 907px;
  }
  .keykey{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/public%2F15292663.188477129-iPad_Pro_Copy.png?alt=media&token=7f88e886-0237-4707-b747-213b02fdd127");
  }
  .artists-list{
    flex-wrap: wrap;
  }
  .artists-item{
    flex-basis: 50%;
    height: 329px;
  }
  .title-box{
    margin-top: 96px;
  }
}

@media (min-width: 1919px) {
  .artists-con{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .artists-list{
    width: 1336px;
  }
}

.annotation{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px 60px 0px;
}

.center-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-column{
  max-width: 1200px;
}

</style>

<template>
  <!--<div class="" :class="{ ['keykey-con layout-rp']: false }">-->
    <!--<div class="layout-ap keykey" v-if="false"></div>-->

    <div class="artists layout-rp" d-style="opacity: 0.5;" :style="`background-image: url(${word('golden-texture')})`">
      <div class="pre-padding"></div>

      <!--<div class="title-bar">
        <div class="resp-con">
          <div class="title-box">
            <div class="title">Live Entertainment</div>


          </div>
        </div>
      </div>-->
              <div class="flex-container">
                <div class="center-wrapper">
                  <div class="center-column">
                    <h1>Live Entertainment</h1>
                    <p>Carpio Music has been providing rich live entertainment for a variety of personal, business and prestegious
                    events all over Hong Kong.</p>
                    <p>The primary enterinment services we provide are <span class="strong">Corporate Events</span>, <span class="strong">Weddings</span>, <span class="strong">Private Events</span> and <span class="strong">In-House Entertainment</span>.
        Our experience and expertise in Live Events make us the preimier choice for Entertainment that are always memorable.</p>
                    <p>No matter the style Jazz, Modern Pop, Latin or Classical – we hope to be part of your event and bring life to it!</p>
                    <br>
                    <br>
                    <br>

                  </div>

                </div>
                <h2 class="text-ctr">Our Artists</h2>
              </div>


      <!--<div class="annotation">
        <div class="resp-con">
          {{ word('desc') }}
        </div>
      </div>-->

      <div class="artists-con">
        <div class="artists-list" :style="`background-image: url(${word('golden-texture')})`">
          <router-link tag="div" :to="word('artist-1-link')" class="artists-item">
            <div class="artists-bg" :style="`background-image: url(${word('artist-1-pic')})`"></div>
            <div class="artists-dimmer"></div>
            <span class="artists-name">{{ word('artist-1-name') }}</span>
            <span class="artists-title">{{ word('artist-1-title') }}</span>
          </router-link>
          <router-link tag="div" :to="word('artist-2-link')" class="artists-item">
            <div class="artists-bg" :style="`background-image: url(${word('artist-2-pic')})`"></div>
            <div class="artists-dimmer"></div>
            <span class="artists-name">{{ word('artist-2-name') }}</span>
            <span class="artists-title">{{ word('artist-2-title') }}</span>
          </router-link>
          <router-link tag="div" :to="word('artist-3-link')" class="artists-item">
            <div class="artists-bg" :style="`background-image: url(${word('artist-3-pic')})`"></div>
            <div class="artists-dimmer"></div>
            <span class="artists-name">{{ word('artist-3-name') }}</span>
            <span class="artists-title">{{ word('artist-3-title') }}</span>
          </router-link>
          <router-link tag="div" :to="word('artist-4-link')" class="artists-item">
            <div class="artists-bg" :style="`background-image: url(${word('artist-4-pic')})`"></div>
            <div class="artists-dimmer"></div>
            <span class="artists-name">{{ word('artist-4-name') }}</span>
            <span class="artists-title">{{ word('artist-4-title') }}</span>
          </router-link>
        </div>
      </div>


      <div class="learn-more-box">
      <router-link tag="div" to="/artists" class="learn-more">MORE ARTISTS</router-link>
    </div>


    </div>

  <!--</div>-->
</template>

<script>
import LearnMore from '@/components/Home/LearnMore'

import { mapGetters } from 'vuex'
export default {
  name: 'P4',
  components: {
    LearnMore
  },
  computed: {
    ...mapGetters({
      'findWord': 'words/findWord'
    }),
    word () {
      return (id) => {
        return this.findWord('home-artists', id)
      }
    }
  }
}
</script>

