<template>
  <div>

    <div class="layout-fp layout-fv layout-tl nav-overlay" :class="{ active: isMenuOpen, 'iOS': iOS }">
      <div class="resp-container" style="height: calc(100% - 200px)">
        <div class="h-100">
          <div class="nav-overlay-padding"></div>
          <div class="nav-menu-list h-100">

            <nav class="overlay-menu h-100">
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">About Us</router-link></li>
                <li><router-link to="/live-entertainment">Live Entertainment</router-link></li>
                <li><router-link to="/artists">Artists</router-link></li>
                <li><router-link to="/bands">Bands</router-link></li>
                <li><router-link to="/education">Education</router-link></li>
                <li><router-link to="/clients">Clients</router-link></li>
                <li><router-link to="/contact-us">Contact Us</router-link></li>
              </ul>
            </nav>

          </div>
          <div class="nav-overlay-padding"></div>
        </div>
      </div>
    </div>

    <div class="layout-tl menu-con z-100" :class="{ ['hideAtTiny']: tiny, ['layout-ap']: !isMenuOpen, ['layout-fp']: isMenuOpen, ['fadeIn']: isMenuOpen }">
      <div class="w-90 d-max-width layout-rp">
        <router-link to="/">
          <img class="title-logo" src="https://firebasestorage.googleapis.com/v0/b/carpio-music.appspot.com/o/cms%2Fimages%2Fhome%2Fcarpiomusic-logo.png?alt=media&token=b8a248e2-66c2-48b8-b327-079b5817e8f3" />
        </router-link>
      </div>
    </div>

    <div class="layout-fp layout-tl menu-con z-100">
      <div class="w-90 d-max-width layout-rp">
        <div class="menu-btn" @click="isMenuOpen = !isMenuOpen; $emit('toggle')" :class="{ ['active']: isMenuOpen }">
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'menu',
  data () {
    return {
      iOS: false,
      isMenuOpen: false
    }
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    open () {
      this.isMenuOpen = this.open
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../layout.scss";

.title-logo{
  max-width: 137.51px;
  max-height: 59.85px;
  top: 27px;
  left: 0px;
  position: absolute;
}
// Buton
.menu-btn {
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 120;
  transition: opacity .25s ease;
  position: absolute;
  top: 42px;
  right: 0px;
}

@media (max-width: 500px) {
  .menu-con.hideAtTiny {
    display: none;
  }
  .title-logo{
    max-width: calc(137.51px * 0.75);
    max-height: calc(59.85px * 0.75);
    top: 27px;
    left: 0px;
  }
  .menu-btn{
    top: 31px;
    transform: scale(0.90);
  }
}

.menu-btn:hover {
  opacity: .7;
}
.menu-btn.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #FFF;
}
.menu-btn.active .middle {
  opacity: 0;
  background: #FFF;
}
.menu-btn.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #FFF;
}
.menu-btn span {
  background: #D7A53A;
  border: none;
  height: 5px;
  width: 35px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .35s ease;
  cursor: pointer;
}
.menu-btn span:nth-of-type(2) {
  top: 11px;
}
.menu-btn span:nth-of-type(3) {
  top: 22px;
}

.menu-con{
  width: 100%;
  display: flex;
  justify-content: center;
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.fadeIn{
  animation: fadeIn 1s 1s both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.nav-overlay{
  z-index: 25;
  color: white;
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  transform: translate3d(0,-100%, 0px);
  background-color: black;
  display: flex;
  justify-content: center;
}

// .nav-overlay.iOS{
//   background-color: rgba(0,0,0,0.75);
//   backdrop-filter: blur(8px);
// }
.nav-overlay.active{
  opacity: 1;
  transform: translate3d(0,0,0px);
}

.nav-overlay-padding{
  height: 100px;
}

.nav-overlay.active li {
  animation: fadeInRight .5s ease forwards;
  animation-delay: .35s;
}
.nav-overlay.active li:nth-of-type(2) {
  animation-delay: .4s;
}
.nav-overlay.active li:nth-of-type(3) {
  animation-delay: .45s;
}
.nav-overlay.active li:nth-of-type(4) {
  animation-delay: .50s;
}
.nav-overlay.active li:nth-of-type(5) {
  animation-delay: .55s;
}
.nav-overlay.active li:nth-of-type(6) {
  animation-delay: .60s;
}
.nav-overlay.active li:nth-of-type(7) {
  animation-delay: .65s;
}
.nav-overlay.active li:nth-of-type(8) {
  animation-delay: .7s;
}
.nav-overlay.active li:nth-of-type(9) {
  animation-delay: .75s;
}
.nav-overlay.active li:nth-of-type(10) {
  animation-delay: .8s;
}

.overlay-menu nav {
  position: relative;
  height: 70%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
  font-family: 'Vollkorn', serif;
  font-weight: 400;
  text-align: center;
}
.overlay-menu ul {
  font-family: 'Playfair Display', serif;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
}
.overlay-menu ul li {
  display: block;
  height: 5%;
  height: calc(100% / 9);
  min-height: 43px;
  position: relative;
  opacity: 0;
}
.overlay-menu ul li a {
  text-align: center;
	/*letter-spacing: 5px;*/
	font-size: 2.2rem;
  display: block;
  position: relative;
  color: #FFF;
  text-decoration: none;
  overflow: hidden;
}
.overlay-menu ul li a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: #FFF;
  transition: .35s;
}

// .overlay-menu ul li a:hover:after {
// .overlay-menu ul li a:hover:after, .overlay-menu ul li a:focus:after {
body:not(.is-touched-once) .overlay-menu ul li a:hover:after,
body:not(.is-touched-once) .overlay-menu ul li a:focus:after,
body:not(.is-touched-once) .overlay-menu ul li a:active:after {
  width: 25%;
  background-color: #D7A53A; /*Gold color*/
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate(20%);
  }
  100% {
    opacity: 1;
    transform: translate(0%);
  }
}

@media (max-width: 500px) {
  .overlay-menu ul li {
    height: calc(100% / 7);
  }
}

</style>
